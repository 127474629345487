import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";

export const useFetchTradesList = () =>
  useQuery({
    queryKey: ["tradesList"],
    queryFn: () => http.get(`${apiUrl}/map/locations/special-filters`),
    select: (data) =>
      data?.data?.data?.trades?.map((trade) => ({
        id: Number(trade.id),
        name: trade.trade_name,
      })),
    initialData: {
      data: {
        data: {
          trades: [],
        },
      },
    },
  });
