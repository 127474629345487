import React, { useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Box,
  IconButton,
  Fab,
  Avatar,
} from "@material-ui/core";

const PartnerCard = ({ partner, onFlyTo, onView }) => {
  const [cardState, setCardState] = useState("normal");

  const handleOnFlyTo = () => {
    onFlyTo();
    setCardState("selected");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 240,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
    },
    workticketIcon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
    avatar: {
      marginRight: 4,
      width: 24,
      height: 24,
      border: "2px solid white",
    },
    workticketNumber: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.text.main,
    },
    stackedAvatar: {
      marginLeft: -12, // Negative margin to overlap avatars
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginTop: 10,
    },
    workticketSubject: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4D4D4D",
      lineHeight: "19px",
    },
    workticketDueDate: {
      height: 21,
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      justifyContent: "center",
      fontSize: 10,
      fontWeight: 600,
      color: theme.palette.primary.main,
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
    },
    cardContent: {
      padding: 12,
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 4,
    },

    partnerName: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1,
    },
    partnerTitle: {
      fontSize: 12,
      fontWeight: 400,
    },
    nameContainer: {
      marginLeft: theme.spacing(1),
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 10,
    },
    imageContainer: {
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 10,
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
    divider: {
      margin: "10px 0",
    },
    actionButtons: {
      display: "flex",
      gap: 10,
      height: 32,
      marginTop: 10,
    },
    darkButton: {
      textTransform: "none",
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      borderColor: theme.palette.secondary.main,
      "&:hover": {
        transition: "0.2s ease-in",
        opacity: 0.8,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    lightButton: {
      textTransform: "none",
      color: "#4F98BC",
      borderColor: "#4F98BC",
      minWidth: 0,
      padding: 5,
    },
    bodyText: {
      fontSize: 12,
    },
  }));

  const classes = useStyles();
  return (
    <Box>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <Avatar
              key={partner.name}
              src={partner.avatar}
              alt={partner.name}
            />
            <Box
              display="flex"
              flexDirection="column"
              className={classes.nameContainer}
            >
              <Typography className={classes.partnerName}>
                {partner.name}
              </Typography>
              <Typography className={classes.partnerTitle}>
                {partner.title}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.tradesContainer}>
            {partner.trades.map((trade) => (
              <Typography key={trade} className={classes.trade}>
                {trade}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.actionButtons}
          >
            {cardState === "normal" && (
              <>
                <Button
                  variant="outlined"
                  onClick={onFlyTo}
                  className={classes.lightButton}
                  fullWidth
                >
                  Go To Partner
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PartnerCard;
