import { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { tradeIcons } from "./tradeIcons"; // You'll need to create this

const trades = [
  { id: "janitorial", name: "Janitorial & Custodial" },
  { id: "carpet", name: "Carpet Care" },
  { id: "window", name: "Window Washing" },
  { id: "pest", name: "Pest Control" },
  { id: "hvac", name: "HVAC & Mechanical" },
  { id: "pressure", name: "Pressure Washing" },
  { id: "plumbing-and-electrical", name: "Plumbing & Electrical" },
  { id: "floor", name: "Floor Care" },
  { id: "painting", name: "Painting" },
  { id: "maintenance", name: "General Maintenance" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 350,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    paddingLeft: 8,
  },
  searchField: {
    // marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 24,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: theme.palette.divider,
      },
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.5, 2),
    },
  },
  selectedTradesContainer: {
    marginTop: theme.spacing(2),
  },
  tradesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
    marginTop: theme.spacing(1.5),
    "&.withBorder": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(1.5),
    },
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&.selected": {
      border: `1px solid #`,
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    // minWidth: 120,
    height: 32,
  },
  tradeIconContainer: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  tradeIcon: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  tradeName: {
    fontSize: 13,
    color: theme.palette.text.primary,
    textAlign: "left",
    lineHeight: 1.2,
  },
  searchResults: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    maxHeight: 200,
    overflowY: "auto",
  },
  searchResultItem: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  searchWrapper: {
    position: "relative",
  },
}));

const TradesFilter = ({
  selectedTrades = [],
  onTradeSelect,
  defaultTrades = [],
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [showResults, setShowResults] = useState(false);

  const filteredTrades = trades.filter(
    (trade) =>
      trade.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedTrades.includes(trade.id) &&
      !defaultTrades.includes(trade.id)
  );

  const handleTradeSelect = (tradeId) => {
    if (selectedTrades.includes(tradeId)) {
      handleTradeRemove(tradeId);
      return;
    }

    if (defaultTrades.includes(tradeId)) {
      onTradeSelect([...selectedTrades, tradeId]);
      return;
    }

    onTradeSelect([...selectedTrades, tradeId]);
    setSearchTerm("");
    setShowResults(false);
  };

  const handleTradeRemove = (tradeId) => {
    onTradeSelect(selectedTrades.filter((id) => id !== tradeId));
  };

  const hasDefaultTrades = defaultTrades.length > 0;
  const hasSelectedTrades =
    selectedTrades.filter((id) => !defaultTrades.includes(id)).length > 0;

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Trades Provided
      </Typography>

      <Box className={classes.searchWrapper}>
        <TextField
          fullWidth
          placeholder="Find Trade"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowResults(true);
          }}
          onFocus={() => setShowResults(true)}
          className={classes.searchField}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        {showResults && searchTerm && (
          <Box className={classes.searchResults}>
            {filteredTrades.map((trade) => (
              <Box
                key={trade.id}
                className={classes.searchResultItem}
                onClick={() => handleTradeSelect(trade.id)}
              >
                {trade.name}
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {hasDefaultTrades && (
        <Box
          className={`${classes.tradesContainer} ${
            hasSelectedTrades ? "withBorder" : ""
          }`}
        >
          {defaultTrades.map((tradeId) => {
            const trade = trades.find((t) => t.id === tradeId);
            if (!trade) return null;
            return (
              <Card
                key={trade.id}
                className={`${classes.card} ${
                  selectedTrades.includes(trade.id) ? "selected" : ""
                }`}
              >
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleTradeSelect(trade.id)}
                >
                  <Box className={classes.tradeIconContainer}>
                    <img
                      src={tradeIcons[trade.id]}
                      alt={`${trade.name} icon`}
                      className={classes.tradeIcon}
                    />
                  </Box>
                  <Typography className={classes.tradeName}>
                    {trade.name}
                  </Typography>
                </CardActionArea>
              </Card>
            );
          })}
        </Box>
      )}

      {hasSelectedTrades && (
        <Box className={classes.tradesContainer}>
          {selectedTrades
            .filter((id) => !defaultTrades.includes(id))
            .map((tradeId) => {
              const trade = trades.find((t) => t.id === tradeId);
              return (
                <Card key={trade.id} className={`${classes.card} selected`}>
                  <CardActionArea
                    onClick={() => handleTradeRemove(trade.id)}
                    className={classes.cardAction}
                  >
                    <Box className={classes.tradeIconContainer}>
                      <img
                        src={tradeIcons[trade.id]}
                        alt={`${trade.name} icon`}
                        className={classes.tradeIcon}
                      />
                    </Box>
                    <Typography className={classes.tradeName}>
                      {trade.name}
                    </Typography>
                  </CardActionArea>
                </Card>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default TradesFilter;
