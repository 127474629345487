import { Box, Card, CardActionArea, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { regionIcons } from "./regionIcons";

const regions = [
  {
    id: "usa",
    label: "Everywhere?",
    value: "usa",
    icon: regionIcons.usa,
  },
  {
    id: "west",
    label: "West",
    value: "west",
    icon: regionIcons.west,
  },
  {
    id: "midwest",
    label: "Midwest",
    value: "midwest",
    icon: regionIcons.midwest,
  },
  {
    id: "northeast",
    label: "Northeast",
    value: "northeast",
    icon: regionIcons.northeast,
  },
  {
    id: "southwest",
    label: "Southwest",
    value: "southwest",
    icon: regionIcons.southwest,
  },
  {
    id: "southeast",
    label: "Southeast",
    value: "southeast",
    icon: regionIcons.southeast,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 400,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    paddingLeft: 8,
  },
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 12,
    boxShadow: "none",
  },
  cardAction: {
    padding: theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  regionIconContainer: {
    width: 100,
    height: 100,
    aspectRatio: "1",
    borderRadius: 8,
    marginBottom: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  regionIcon: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: 8,
    border: (props) =>
      props.isSelected ? `1px solid #4F98BC` : "1px solid #e0e0e0",
    transition: "border-color 0.2s ease",
  },
  regionName: {
    fontSize: 12,
    color: theme.palette.text.main,
    textAlign: "center",
  },
}));

const RegionFilter = ({ selectedRegions = [], onRegionSelect }) => {
  const classes = useStyles({ isSelected: false });

  const handleRegionSelect = (region) => {
    let newSelectedRegions;

    // If "Everywhere?" is clicked
    if (region === "usa") {
      newSelectedRegions = ["usa"];
    }
    // If another region is clicked while "Everywhere?" is selected
    else if (selectedRegions.includes("usa")) {
      newSelectedRegions = [region];
    }
    // Toggle
    else {
      if (selectedRegions.includes(region)) {
        newSelectedRegions = selectedRegions.filter((r) => r !== region);
      } else {
        newSelectedRegions = [...selectedRegions, region];
      }
    }

    onRegionSelect(newSelectedRegions);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Region
      </Typography>

      <Grid container spacing={1} className={classes.gridContainer}>
        {regions.map((region) => (
          <Grid item xs={4} sm={4} key={region.id}>
            <Card className={classes.card}>
              <CardActionArea
                onClick={() => handleRegionSelect(region.value)}
                className={classes.cardAction}
              >
                <div className={classes.regionIconContainer}>
                  <img
                    src={region.icon}
                    alt={`Map of ${region.label}`}
                    className={classes.regionIcon}
                    style={{
                      borderColor: selectedRegions.includes(region.value)
                        ? "#4F98BC"
                        : "#e0e0e0",
                    }}
                  />
                </div>
                <Typography className={classes.regionName}>
                  {region.label}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RegionFilter;
