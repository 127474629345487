import React, { useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Box,
  IconButton,
  Fab,
  Avatar,
} from "@material-ui/core";

import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import mapIcon from "assets/icons/map-icon.png";
import CommentsIcon from "assets/icons/commentsIcon";
import briefcaseIcon from "assets/icons/briefcase-icon.png";
import userIcon from "assets/icons/user-outline-icon.png";
import WorkticketIcon from "assets/icons/workticketIcon";
import { mapIcons } from "./mapIcons";
import moment from "moment";
import CommentDialog from "../commentDialog";

const WorkticketCard = ({ workticket, onComment, onFlyTo, onView }) => {
  const [cardState, setCardState] = useState("normal");
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  const handleOnFlyTo = () => {
    onFlyTo();
    setCardState("selected");
  };

  const { service_category, number, subject, due_date, users } = workticket;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 240,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
    },
    workticketIcon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
    avatar: {
      marginRight: 4,
      width: 24,
      height: 24,
      border: "2px solid white",
    },
    workticketNumber: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.text.main,
    },
    stackedAvatar: {
      marginLeft: -12, // Negative margin to overlap avatars
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginTop: 10,
    },
    workticketSubject: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4D4D4D",
      lineHeight: "19px",
    },
    workticketDueDate: {
      height: 21,
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      justifyContent: "center",
      fontSize: 10,
      fontWeight: 600,
      color: theme.palette.primary.main,
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
    },
    cardContent: {
      padding: 12,
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 4,
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 10,
    },
    imageContainer: {
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 10,
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
    divider: {
      margin: "10px 0",
    },
    actionButtons: {
      display: "flex",
      gap: 10,
      height: 32,
      marginTop: 10,
    },
    darkButton: {
      textTransform: "none",
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      borderColor: theme.palette.secondary.main,
      "&:hover": {
        transition: "0.2s ease-in",
        opacity: 0.8,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    lightButton: {
      textTransform: "none",
      color: "#4F98BC",
      borderColor: "#4F98BC",
      minWidth: 0,
      padding: 5,
    },
    bodyText: {
      fontSize: 12,
    },
  }));

  const classes = useStyles();
  return (
    <Box>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <img
              className={classes.workticketIcon}
              src={mapIcons[workticket.workticket_type][workticket.status][0]}
              alt="workticket"
            />
            {users.slice(0, 2).map((user, index) => (
              <Avatar
                key={user.name}
                src={user.avatar}
                alt={user.name}
                className={`${classes.avatar} ${
                  index > 0 ? classes.stackedAvatar : ""
                }`}
              />
            ))}
            <Typography className={classes.workticketNumber}>
              {number}
            </Typography>
          </Box>
          <Box marginTop={1}>
            <Typography className={classes.workticketSubject}>
              {subject}
            </Typography>
          </Box>
          <Box
            marginTop={1}
            display="flex"
            alignItems="center"
            className={classes.statusContainer}
          >
            <WorkticketStatusChip
              status={workticket.status}
              due_date={due_date}
              type={workticket.type}
            />
            <Typography className={classes.workticketDueDate}>
              Due: {moment(due_date).format("MM/DD/YYYY")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start" marginTop={1}>
            <img
              src={briefcaseIcon}
              alt="briefcase"
              style={{ marginRight: 6 }}
            />

            <Typography variant="body2" className={classes.bodyText}>
              <span style={{ fontWeight: 600 }}>
                {workticket?.location?.name}
              </span>
            </Typography>
          </Box>

          <Box display="flex" alignItems="flex-start" marginTop={1}>
            <img src={mapIcon} alt="map" style={{ marginRight: 6 }} />

            <Typography variant="body2" className={classes.bodyText}>
              <span style={{ fontWeight: 600 }}>Address:</span>{" "}
              {workticket?.location?.address}. {workticket?.location?.city},{" "}
              {workticket?.location?.state}.
            </Typography>
          </Box>
          <Box className={classes.tradesContainer}>
            <Typography key={service_category} className={classes.trade}>
              {service_category}
            </Typography>
          </Box>
          <Divider className={classes.divider} />

          <Box display="flex" alignItems="flex-start" marginTop={1}>
            <img src={userIcon} alt="map" style={{ marginRight: 6 }} />

            <Typography variant="body2" className={classes.bodyText}>
              <span style={{ fontWeight: 600 }}>
                Encompass Success Manager:{" "}
              </span>
              {workticket?.location?.manager?.first_name}{" "}
              {workticket?.location?.manager?.last_name}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.actionButtons}
          >
            {cardState === "normal" && (
              <>
                <Button
                  variant="contained"
                  onClick={handleOnFlyTo}
                  className={classes.darkButton}
                  fullWidth
                >
                  Go to
                </Button>
                <Button
                  variant="outlined"
                  onClick={onView}
                  className={classes.lightButton}
                  fullWidth
                >
                  View
                </Button>
              </>
            )}

            {cardState === "selected" && (
              <>
                <Button
                  variant="contained"
                  className={classes.darkButton}
                  style={{ flex: 6 }}
                >
                  View
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setCommentDialogOpen(true);
                  }}
                  className={classes.lightButton}
                  style={{ flex: 1 }}
                >
                  <CommentsIcon strokeColor="#4F98BC" />
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <CommentDialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}
        title={number}
        subtitle={"Add a comment to this Workticket"}
        icon={
          <div style={{ marginBottom: "5px" }}>
            <WorkticketIcon height={30} width={30} />
          </div>
        }
        onSubmit={(comment) => console.log("Comment submitted:", comment)}
      />
    </Box>
  );
};

export default WorkticketCard;
