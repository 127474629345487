import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationDetails = () => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationDetails", locationId],
    queryFn: () => http.get(`${apiUrl}/locations/${locationId}`),
    enabled: !!locationId,
    select: (data) => data?.data?.data?.location,
  });
};
