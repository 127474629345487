import React from "react";
import Box from "@material-ui/core/Box";
import { formatNumber } from "components/util/stringFormat";
import useStyles from "../styles";

const ChartHeaderDetails = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.chartHeaderDetails}>
      {data.map((item, index) => (
        <Box className={classes.chartHeaderItem} key={index}>
          <Box
            className={classes.chartHeaderBullet}
            style={{
              backgroundColor: item.color,
              border: item?.border ?? "none",
            }}
          ></Box>
          <Box
            className={classes.chartHeaderLabel}
            style={{ fontSize: 12, whiteSpace: "nowrap" }}
          >
            {item.label}
          </Box>
          {item.type === "percent" ? (
            <Box
              className={classes.chartHeaderPercent}
              style={{
                backgroundColor: item.badgeColor,
                fontSize: 12,
                whiteSpace: "nowrap",
              }}
            >
              {item.value} %
            </Box>
          ) : (
            <Box
              className={classes.chartHeaderNumber}
              style={{ fontSize: 12, whiteSpace: "nowrap" }}
            >
              {formatNumber(item.value)}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ChartHeaderDetails;
