import { Box, makeStyles, IconButton } from "@material-ui/core";
import classNames from "classnames";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PushPinIcon } from "assets/icons/pushPinIcon";
import { useLocationDetailsContext } from "pages/locationDetails/context";
import {
  useToggleImagePin,
  useDeleteLocationImage,
} from "pages/locationDetails/api";

const useStyles = makeStyles((theme) => ({
  root: {
    aspectRatio: "135 / 98",
    borderRadius: 8,
    position: "relative",
    overflow: "hidden",
    boxShadow: "0px 2px 20px 0px rgba(89, 89, 89, 0.25)",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  actionsContainer: {
    position: "absolute",
    top: "50%",
    right: 8,
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  actionButton: {
    padding: 0,
    width: 32,
    height: 32,
    backgroundColor: "#F8F8F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
    "&:disabled": {
      opacity: 0.75,
      backgroundColor: "#F8F8F8",
    },
  },
  pinnedImageButton: {
    backgroundColor: "#E8F8CB",
    "&:hover": {
      backgroundColor: "#E8F8CB",
    },
    "&:disabled": {
      backgroundColor: "#E8F8CB",
    },
  },
  actionIcon: {
    width: 20,
    height: 20,
    color: "#747474",
  },
  pinnedImageIcon: {
    color: "#328914",
  },
  loadingIcon: {
    animation: "$spin 1.5s linear infinite",
  },
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

export const ImageTile = ({ image }) => {
  const classes = useStyles();
  const { images } = useLocationDetailsContext();
  const { mutate: toggleImagePin } = useToggleImagePin();
  const { mutate: updateLocationImages } = useDeleteLocationImage();
  const isPinned = Number(image.is_pinned);
  const pinningDisabled =
    images.filter((img) => Number(img.is_pinned)).length >= 4;

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={image.file_url} alt="location" />
      <Box className={classes.actionsContainer}>
        <IconButton
          className={classNames(
            classes.actionButton,
            isPinned && classes.pinnedImageButton
          )}
          onClick={() => toggleImagePin(image.id)}
          disabled={pinningDisabled && !isPinned}
        >
          <PushPinIcon
            className={classes.actionIcon}
            color={isPinned ? "#328914" : "#747474"}
          />
        </IconButton>
        <IconButton
          className={classes.actionButton}
          onClick={() => updateLocationImages(image.id)}
        >
          <TrashIcon className={classes.actionIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};
