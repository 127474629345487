import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
  Fab,
} from "@material-ui/core";
import { Room as RoomIcon } from "@material-ui/icons";

import ImageCarousel from "../../../components/common/Carousel/ImageCarousel";
import CommentsIcon from "assets/icons/commentsIcon";
import canvassedIcon from "assets/icons/document-magnifying-glass-icon.png";
import CommentDialog from "../commentDialog";
import mapIcon from "assets/icons/map-icon.png";
const LocationCard = ({
  customer,
  location,
  lastCanvassed,
  address,
  images,
  trades,
  onCanvass,
  onComment,
  onFlyTo,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 240,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
    },
    canvassButton: {
      position: "absolute",
      top: 12,
      right: 12,
      borderRadius: 50,
      backgroundColor: "white",
      color: "#4F98BC",
      textTransform: "none",
      paddingLeft: 20,
      paddingRight: 20,
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: "white",
        opacity: 0.8,
      },
    },
    cardContent: {
      padding: 12,
    },
    commentButton: {
      position: "absolute",
      top: 12,
      left: 12,
      borderRadius: 50,
      backgroundColor: "white",
      width: 35,
      height: 35,
      padding: 8,
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: "white",
        opacity: 0.8,
      },
    },
    flyToButton: {
      marginTop: 10,
      textTransform: "none",
      color: "#4F98BC",
      borderColor: "#4F98BC",
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: "#4F98BC",
        color: "white",
      },
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 4,
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 10,
    },
    imageContainer: {
      // border: "solid 1px red",
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 10,
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
  }));

  const classes = useStyles();
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  return (
    <Box>
      <Card className={classes.root}>
        <Box className={classes.imageContainer}>
          <ImageCarousel />
          <Fab
            className={classes.commentButton}
            onClick={() => {
              console.log("=== commentDialogOpen: ", commentDialogOpen);
              setCommentDialogOpen(true);
            }}
          >
            <CommentsIcon />
          </Fab>
          <Button className={classes.canvassButton}>Canvass Now</Button>
        </Box>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.customerName}>{customer}</Typography>
          <Typography className={classes.locationHeader}>{location}</Typography>
          <Box display="flex" alignItems="flex-start">
            <img
              src={canvassedIcon}
              alt="canvassed"
              style={{ marginRight: 4 }}
            />
            <Typography variant="body2">
              <span style={{ fontWeight: 600 }}>Last Canvassed:</span>{" "}
              {lastCanvassed}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start" marginTop={1}>
            <img src={mapIcon} alt="map" style={{ marginRight: 6 }} />
            <Typography variant="body2">
              <span style={{ fontWeight: 600 }}>Address:</span> {address}
            </Typography>
          </Box>
          <Box className={classes.tradesContainer}>
            {trades.map((trade) => (
              <Typography key={trade} className={classes.trade}>
                {trade}
              </Typography>
            ))}
          </Box>
          {onFlyTo && (
            <Button
              variant="outlined"
              onClick={onFlyTo}
              className={classes.flyToButton}
              fullWidth
            >
              Go to Location
            </Button>
          )}
        </CardContent>
      </Card>
      <CommentDialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}
        title={location}
        icon={
          <RoomIcon
            style={{
              fontSize: "2rem",
              marginBottom: "10px",
              color: "#4D4D4D",
            }}
          />
        }
        subtitle={"Add a comment to this location"}
        onSubmit={(comment) => console.log("Comment submitted:", comment)}
      />
    </Box>
  );
};

export default LocationCard;
