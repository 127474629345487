import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ClickAwayListener,
  Paper,
  Box,
  TextField,
} from "@material-ui/core";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

const useStyles = makeStyles((theme) => ({
  accordion: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 200ms ease",
    "&.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  accordionSummary: {
    minHeight: 0,
    margin: 0,
    "&.Mui-expanded": {
      minHeight: 0,
      margin: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 8,
    },
  },
  searchResults: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
    marginTop: theme.spacing(0.5),
    maxHeight: 200,
    overflowY: "scroll",
  },
  selectedItems: {
    "& .MuiListItem-root": {
      height: 20,
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
    },
  },
  noResults: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  selectedItem: {
    paddingLeft: 4,
  },
}));

const SearchableFilter = ({
  title,
  options,
  selectedItems = [],
  onItemSelect,
  onItemToggle,
  placeholder = "Write a name...",
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [visibleCount, setVisibleCount] = useState(100);

  const filteredOptions = options
    .filter((option) => {
      if (searchText === "") {
        return true;
      }
      if (option?.name) {
        return option.name.toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    })
    .slice(0, visibleCount);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setShowResults(true);
    setVisibleCount(50);
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && visibleCount < options.length) {
      setVisibleCount((prevCount) => prevCount + 50);
    }
  };

  const handleSelect = (item) => {
    onItemSelect(item);
    setSearchText("");
    setShowResults(false);
  };

  return (
    <Accordion
      defaultExpanded={selectedItems.length > 0}
      classes={{ root: classes.accordion }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightRoundedIcon />}
        classes={{
          expandIcon: classes.expandIcon,
          root: classes.accordionSummary,
        }}
        aria-controls={`filter-${title}-content`}
        id={`filter-${title}`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <Box width="100%">
          <ClickAwayListener onClickAway={() => setShowResults(false)}>
            <Box position="relative">
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                placeholder={placeholder}
                value={searchText}
                onChange={handleSearchChange}
                onFocus={() => setShowResults(true)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                  notched: false,
                  label: null,
                }}
              />

              {showResults && (
                <Paper
                  className={classes.searchResults}
                  onScroll={handleScroll}
                >
                  <List dense>
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((option) => (
                        <ListItem
                          key={option.id}
                          button
                          onClick={() => handleSelect(option)}
                        >
                          <ListItemText primary={option.name} />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary="No results found"
                          className={classes.noResults}
                        />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              )}
            </Box>
          </ClickAwayListener>

          {selectedItems.length > 0 && (
            <List dense className={classes.selectedItems}>
              {selectedItems.map((item) => (
                <ListItem key={item?.id} dense className={classes.selectedItem}>
                  <Checkbox
                    edge="start"
                    checked={true}
                    size="small"
                    onChange={() => onItemToggle(item)}
                  />
                  <ListItemText dense primary={item.name} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchableFilter;
