import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    letterSpacing: "0.1px",
    height: 24,
    padding: "0 12px",
    fontWeight: 700,
    lineHeight: "24px",
    minWidth: 0,
    textTransform: "none",
    color: theme.palette.secondary.main,
    borderColor: "#D9D9D9",
  },
}));

export const ViewButton = (props) => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <Button
      className={classes.root}
      variant="outlined"
      disableElevation
      onClick={onClick}
    >
      View
    </Button>
  );
};
