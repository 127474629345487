import { Box, useTheme, useMediaQuery, makeStyles } from "@material-ui/core";
import { DesktopLayout, MobileLayout } from "./layout";
import { LocationDetailsProvider } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    overflowX: "hidden",
  },
}));

export const LocationDetails = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LocationDetailsProvider>
      <Box className={classes.root}>
        {isMobile ? <MobileLayout /> : <DesktopLayout />}
      </Box>
    </LocationDetailsProvider>
  );
};
