import midwestIcon from "assets/icons/regions/usa-midwest.png";
import northeastIcon from "assets/icons/regions/usa-northeast.png";
import southeastIcon from "assets/icons/regions/usa-southeast.png";
import southwestIcon from "assets/icons/regions/usa-southwest.png";
import westIcon from "assets/icons/regions/usa-west.png";
import usaIcon from "assets/icons/regions/usa.png";

export const regionIcons = {
  midwest: midwestIcon,
  northeast: northeastIcon,
  southeast: southeastIcon,
  southwest: southwestIcon,
  west: westIcon,
  usa: usaIcon,
};
