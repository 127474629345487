import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ChartBarHorizontal = (props) => {
  const {
    colors,
    customTooltip: CustomTooltip,
    data,
    dataKeys,
    height,
    barSize,
  } = props;
  return (
    <ResponsiveContainer width="100%" height={height ?? 350}>
      <BarChart data={data} layout="vertical">
        <CartesianGrid horizontal={false} />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" />

        <Tooltip
          contentStyle={{ backgroundColor: "#16457A" }}
          labelStyle={{ color: "#ffffff" }}
          content={CustomTooltip ? <CustomTooltip /> : null}
          cursor={{ fill: "transparent" }}
        />
        <Bar
          barSize={barSize ? barSize : 128}
          dataKey={dataKeys ? dataKeys[0] : "data1"}
          stackId="x"
          fill={colors[0]}
        />
        <Bar
          barSize={barSize ? barSize : 128}
          dataKey={dataKeys ? dataKeys[1] : "data2"}
          stackId="x"
          fill={colors[1]}
        />
        <Bar
          barSize={barSize ? barSize : 128}
          dataKey={dataKeys ? dataKeys[2] : "data3"}
          stackId="x"
          fill={colors[2]}
        />
        <Bar
          barSize={barSize ? barSize : 128}
          dataKey={dataKeys ? dataKeys[3] : "data4"}
          stackId="x"
          fill={colors[3]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartBarHorizontal;
