import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";

export const useDeleteLocationComment = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (commentId) =>
      http.delete(`${apiUrl}/locations/${locationId}/comments/${commentId}`),
    onMutate: async (commentId) => {
      await queryClient.cancelQueries(["locationComments", locationId]);

      const previousComments = queryClient.getQueryData([
        "locationComments",
        locationId,
      ]);

      queryClient.setQueryData(["locationComments", locationId], (old) => {
        if (!old?.data?.data) return old;

        const updatedComments = { ...old };

        ["internal", "partner", "customer"].forEach((tab) => {
          if (updatedComments.data.data[tab]?.comments) {
            updatedComments.data.data[tab].comments = updatedComments.data.data[
              tab
            ].comments.filter((comment) => comment.id !== commentId);
          }
        });

        return updatedComments;
      });

      return { previousComments };
    },
    onError: (_, __, context) => {
      if (context?.previousComments) {
        queryClient.setQueryData(
          ["locationComments", locationId],
          context.previousComments
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries(["locationComments", locationId]),
  });
};
