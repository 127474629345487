import React from "react";

export const PushPinIcon = ({ className, color = "#747474" }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_495_8410)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6119 14.6377C13.8889 14.3607 13.9125 13.9365 13.718 13.5888C13.1759 12.6342 13.3114 11.4027 14.1246 10.5895L17.0709 7.64323L17.6601 8.23249C17.9842 8.55658 18.5146 8.55658 18.8386 8.23249C19.1627 7.9084 19.1627 7.37807 18.8386 7.05398L12.9461 1.16142C12.622 0.837331 12.0917 0.837331 11.7676 1.16142C11.4435 1.48551 11.4435 2.01584 11.7676 2.33993L12.3568 2.92919L9.41056 5.87547C8.59738 6.68864 7.36584 6.82417 6.41124 6.28205C6.06358 6.0876 5.63932 6.11117 5.36237 6.38812L5.28577 6.46472C4.96168 6.78881 4.96168 7.31914 5.28577 7.64323L8.22026 10.5777L4.10726 14.7143C3.78316 15.0384 3.78316 15.5687 4.10726 15.8928C4.43135 16.2169 4.96168 16.2169 5.28577 15.8928L9.39877 11.7562L12.3568 14.7143C12.6809 15.0384 13.2113 15.0384 13.5353 14.7143L13.6119 14.6377Z"
        fill={color}
      />
    </g>
  </svg>
);
