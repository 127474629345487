import alabamaIcon from "assets/icons/states/usa-AL.png";
import alaskaIcon from "assets/icons/states/usa-AK.png";
import arizonaIcon from "assets/icons/states/usa-AZ.png";
import arkansasIcon from "assets/icons/states/usa-AR.png";
import californiaIcon from "assets/icons/states/usa-CA.png";
import coloradoIcon from "assets/icons/states/usa-CO.png";
import connecticutIcon from "assets/icons/states/usa-CT.png";
import delawareIcon from "assets/icons/states/usa-DE.png";
import floridaIcon from "assets/icons/states/usa-FL.png";
import georgiaIcon from "assets/icons/states/usa-GA.png";
import hawaiiIcon from "assets/icons/states/usa-HI.png";
import idahoIcon from "assets/icons/states/usa-ID.png";
import illinoisIcon from "assets/icons/states/usa-IL.png";
import indianaIcon from "assets/icons/states/usa-IN.png";
import iowaIcon from "assets/icons/states/usa-IA.png";
import kansasIcon from "assets/icons/states/usa-KS.png";
import kentuckyIcon from "assets/icons/states/usa-KY.png";
import louisianaIcon from "assets/icons/states/usa-LA.png";
import maineIcon from "assets/icons/states/usa-ME.png";
import marylandIcon from "assets/icons/states/usa-MD.png";
import massachusettsIcon from "assets/icons/states/usa-MA.png";
import michiganIcon from "assets/icons/states/usa-MI.png";
import minnesotaIcon from "assets/icons/states/usa-MN.png";
import mississippiIcon from "assets/icons/states/usa-MS.png";
import missouriIcon from "assets/icons/states/usa-MO.png";
import montanaIcon from "assets/icons/states/usa-MT.png";
import nebraskaIcon from "assets/icons/states/usa-NE.png";
import nevadaIcon from "assets/icons/states/usa-NV.png";
import newHampshireIcon from "assets/icons/states/usa-NH.png";
import newJerseyIcon from "assets/icons/states/usa-NJ.png";
import newMexicoIcon from "assets/icons/states/usa-NM.png";
import newYorkIcon from "assets/icons/states/usa-NY.png";
import northCarolinaIcon from "assets/icons/states/usa-NC.png";
import northDakotaIcon from "assets/icons/states/usa-ND.png";
import ohioIcon from "assets/icons/states/usa-OH.png";
import oklahomaIcon from "assets/icons/states/usa-OK.png";
import oregonIcon from "assets/icons/states/usa-OR.png";
import pennsylvaniaIcon from "assets/icons/states/usa-PA.png";
import rhodeIslandIcon from "assets/icons/states/usa-RI.png";
import southCarolinaIcon from "assets/icons/states/usa-SC.png";
import southDakotaIcon from "assets/icons/states/usa-SD.png";
import tennesseeIcon from "assets/icons/states/usa-TN.png";
import texasIcon from "assets/icons/states/usa-TX.png";
import utahIcon from "assets/icons/states/usa-UT.png";
import vermontIcon from "assets/icons/states/usa-VT.png";
import virginiaIcon from "assets/icons/states/usa-VA.png";
import washingtonIcon from "assets/icons/states/usa-WA.png";
import westVirginiaIcon from "assets/icons/states/usa-WV.png";
import wisconsinIcon from "assets/icons/states/usa-WI.png";
import wyomingIcon from "assets/icons/states/usa-WY.png";

export const stateIcons = {
  AL: alabamaIcon,
  AK: alaskaIcon,
  AZ: arizonaIcon,
  AR: arkansasIcon,
  CA: californiaIcon,
  CO: coloradoIcon,
  CT: connecticutIcon,
  DE: delawareIcon,
  FL: floridaIcon,
  GA: georgiaIcon,
  HI: hawaiiIcon,
  ID: idahoIcon,
  IL: illinoisIcon,
  IN: indianaIcon,
  IA: iowaIcon,
  KS: kansasIcon,
  KY: kentuckyIcon,
  LA: louisianaIcon,
  ME: maineIcon,
  MD: marylandIcon,
  MA: massachusettsIcon,
  MI: michiganIcon,
  MN: minnesotaIcon,
  MS: mississippiIcon,
  MO: missouriIcon,
  MT: montanaIcon,
  NE: nebraskaIcon,
  NV: nevadaIcon,
  NH: newHampshireIcon,
  NJ: newJerseyIcon,
  NM: newMexicoIcon,
  NY: newYorkIcon,
  NC: northCarolinaIcon,
  ND: northDakotaIcon,
  OH: ohioIcon,
  OK: oklahomaIcon,
  OR: oregonIcon,
  PA: pennsylvaniaIcon,
  RI: rhodeIslandIcon,
  SC: southCarolinaIcon,
  SD: southDakotaIcon,
  TN: tennesseeIcon,
  TX: texasIcon,
  UT: utahIcon,
  VT: vermontIcon,
  VA: virginiaIcon,
  WA: washingtonIcon,
  WV: westVirginiaIcon,
  WI: wisconsinIcon,
  WY: wyomingIcon,
};
