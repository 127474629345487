import React, { useRef, useEffect, useState, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DashboardHeader from "../dashboardHeader";
import { mapIcons } from "./mapIcons";
import theme from "theme";
import {
  LocationLayerManager,
  WorkticketLayerManager,
  PartnerLayerManager,
  BuildingsLayerManager,
} from "./MapLayerManager";
import LayerToggleControl from "./layerToggleControl";

import {
  useDashboardDispatch,
  useDashboardState,
  getDashboardLocationList,
} from "contexts/dashboardContext";
import SpecialFilters from "./specialFilters";
import Papa from "papaparse";

import workticketCSV from "lib/test-worktickets.csv";
import locationJSON from "lib/jobs.json";
import partnersJSON from "lib/test-partners.json";
import MapHeader from "./mapHeader";
// Replace with your actual Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9lbGJyZXdlcjAxIiwiYSI6ImNtMmIwdTdjbjAwaG8ya3B0MHI5aW0zcmkifQ.Ms84oNVTGNwi-B4vF-9zOQ";

const MAX_MAP_PITCH_IN_DEGREES = 60;
const DEFAULT_WORKTICKET_TYPE = 0;
const DEFAULT_BADGE_STATUS = 0;
const useInitializeMap = (mapContainer, setMapLoaded, handleZoom) => {
  const map = useRef(null);

  const style = document.createElement("style");
  style.textContent = `
   .mapboxgl-popup-content {
     padding: 0;
     background: none;
     box-shadow: none;
   }
   .mapboxgl-popup-tip {
     display: none;
   }
   .location-hover-popup {
     background-color: #fff;
     border-radius: 4px;
     border: 1px solid rgba(232, 248, 203, 1);
     padding: 5px;
   }    
 `;

  document.head.appendChild(style);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-98.5795, 39.8283], // Center of the US
        zoom: 4,
      });

      map.current.on("load", () => {
        setMapLoaded(true);
      });

      map.current.on("zoom", handleZoom);
    }

    return () => {
      // if (map.current) {
      //   map.current.remove();
      // }
    };
  }, [mapContainer, setMapLoaded, handleZoom]);

  return map;
};

const DashboardMapbox = ({ isSurfaceUser }) => {
  const mapContainer = useRef(null);
  const dispatch = useDashboardDispatch();
  const [mapLoaded, setMapLoaded] = useState(false);
  // const dashboardState = useDashboardState();
  const [locations, setLocations] = useState(locationJSON?.data?.jobs);
  const [worktickets, setWorktickets] = useState([]);
  const isFlyingRef = useRef(false); // Use a ref to track flying status
  const isClickProcessingRef = useRef(false);
  const isZoomingWithButtonsRef = useRef(false);
  const currentPopupRef = useRef(null);
  const hoverPopupRef = useRef(null);
  const [visibleLayers, setVisibleLayers] = useState([
    "LOCATIONS",
    "WORKTICKETS",
    "PARTNERS",
  ]);

  const [layerManagers, setLayerManagers] = useState({
    LOCATIONS: null,
    WORKTICKETS: null,
    PARTNERS: null,
  });

  const mockDashboardState = {
    mapLocationList: {
      data: {
        jobs: locationJSON,
      },
    },
    mapPartnerList: {
      data: {
        partners: partnersJSON,
      },
    },
  };

  const dashboardState = mockDashboardState;

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleZoom = () => {
    if (!map.current || isFlyingRef.current || isZoomingWithButtonsRef.current)
      return;
    const zoomLevel = map.current.getZoom();
    const targetPitch = calculatePitch(zoomLevel, MAX_MAP_PITCH_IN_DEGREES);
    map.current.setPitch(targetPitch);
  };

  const map = useInitializeMap(mapContainer, setMapLoaded, handleZoom);

  useEffect(() => {
    console.log("Fetching dashboard location list");
    getDashboardLocationList(dispatch, isSurfaceUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSurfaceUser]);

  useEffect(() => {
    Papa.parse(workticketCSV, {
      download: true,
      header: true,
      complete: (results) => {
        setWorktickets(results.data);
      },
    });
  }, []);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (
      mapLoaded &&
      map.current &&
      dashboardState?.mapLocationList?.data?.jobs &&
      worktickets.length > 0
    ) {
      loadMapImages(map.current);

      const options = {
        hoverPopupRef,
        currentPopupRef,
        isFlyingRef,
        isClickProcessingRef,
        theme,
      };

      const baseRadius = 0.000001;
      const maxRadius = baseRadius * Math.sqrt(worktickets.length);

      const locationLayerManager = new LocationLayerManager(
        map.current,
        options
      );
      const workticketLayerManager = new WorkticketLayerManager(
        map.current,
        options
      );
      const partnerLayerManager = new PartnerLayerManager(map.current, options);
      const buildingsLayerManager = new BuildingsLayerManager(
        map.current,
        options
      );

      setLayerManagers({
        LOCATIONS: locationLayerManager,
        WORKTICKETS: workticketLayerManager,
        PARTNERS: partnerLayerManager,
        BUILDINGS: buildingsLayerManager,
      });

      const locationData = locationsAsGeoJSON(dashboardState);
      const workticketData = workticketsAsGeoJSON(worktickets, maxRadius);
      const partnerData = partnersAsGeoJSON(dashboardState);

      locationLayerManager.addLayersForType("LOCATIONS", locationData);
      workticketLayerManager.addLayersForType("WORKTICKETS", workticketData);
      partnerLayerManager.addLayersForType("PARTNERS", partnerData);
      buildingsLayerManager.addLayersForType("BUILDINGS");

      Promise.all([
        locationLayerManager.addLayersForType("LOCATIONS", locationData),
        workticketLayerManager.addLayersForType("WORKTICKETS", workticketData),
        partnerLayerManager.addLayersForType("PARTNERS", partnerData),
        buildingsLayerManager.addLayersForType("BUILDINGS"),
      ]).then(() => {
        setIsDataLoaded(true);
      });
    }
  }, [mapLoaded, dashboardState?.mapLocationList?.data?.jobs, worktickets]);

  const handleToggleLayer = useCallback(
    (layerType) => {
      setVisibleLayers((prev) => {
        const newVisibleLayers = prev.includes(layerType)
          ? prev.filter((layer) => layer !== layerType)
          : [...prev, layerType];

        // Update layer visibility
        const manager = layerManagers[layerType];
        if (manager) {
          manager.setVisibility(newVisibleLayers.includes(layerType));
        }

        return newVisibleLayers;
      });
    },
    [layerManagers]
  );

  const handleMyLocationClick = () => {
    if (!map.current) return;

    if ("geolocation" in navigator) {
      setSnackbar({
        open: true,
        message: "Fetching your location...",
        severity: "info",
      });

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coordinates = [longitude, latitude];

          // Clear loading snackbar
          setSnackbar((prev) => ({ ...prev, open: false }));

          isFlyingRef.current = true;

          map.current.flyTo({
            center: coordinates,
            zoom: 12,
            essential: true,
          });

          map.current.once("moveend", () => {
            isFlyingRef.current = false;
          });
        },
        (error) => {
          console.error("Geolocation error:", error);
          setSnackbar({
            open: true,
            message:
              "Unable to access your location. Please check your browser permissions.",
            severity: "error",
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setSnackbar({
        open: true,
        message: "Geolocation is not supported by your browser",
        severity: "error",
      });
    }
  };

  const handleZoomIn = () => {
    if (!map.current) return;
    isZoomingWithButtonsRef.current = true;
    map.current.easeTo({
      zoom: map.current.getZoom() + 1,
      duration: 300,
    });
    map.current.once("moveend", () => {
      console.log("=== zooming in complete");
      isZoomingWithButtonsRef.current = false;
    });
  };

  const handleZoomOut = () => {
    if (!map.current) return;
    isZoomingWithButtonsRef.current = true;
    map.current.easeTo({
      zoom: map.current.getZoom() - 1,
      duration: 300,
    });
    map.current.once("moveend", () => {
      console.log("=== zooming out complete");
      isZoomingWithButtonsRef.current = false;
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <DashboardHeader />
        <MapHeader />
        {isDataLoaded && <SpecialFilters visibleLayers={visibleLayers} />}
        <Box
          ref={mapContainer}
          sx={{
            flexGrow: 1,
            position: "relative",
            "& .mapboxgl-canvas": {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            },
          }}
        />
      </Box>
      {isDataLoaded && (
        <LayerToggleControl
          visibleLayers={visibleLayers}
          onToggleLayer={handleToggleLayer}
          onMyLocationClick={handleMyLocationClick}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default DashboardMapbox;

function locationsAsGeoJSON(dashboardState) {
  return {
    type: "FeatureCollection",
    features: dashboardState.mapLocationList.data.jobs
      .map((location) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(location.longitude),
            parseFloat(location.latitude),
          ],
        },
        properties: location,
      }))
      .filter(
        (feature) =>
          !isNaN(feature.geometry.coordinates[0]) &&
          !isNaN(feature.geometry.coordinates[1])
      ),
  };
}

function partnersAsGeoJSON(dashboardState) {
  return {
    type: "FeatureCollection",
    features: dashboardState.mapPartnerList.data.partners
      .map((partner) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(partner.longitude),
            parseFloat(partner.latitude),
          ],
        },
        properties: partner,
      }))
      .filter(
        (feature) =>
          !isNaN(feature.geometry.coordinates[0]) &&
          !isNaN(feature.geometry.coordinates[1])
      ),
  };
}

function workticketsAsGeoJSON(worktickets, maxRadius) {
  return {
    type: "FeatureCollection",
    features: worktickets
      .map((ticket, index) => {
        const [offsetX, offsetY] = randomOffset(maxRadius);
        const offsetLng = parseFloat(ticket.longitude) + offsetX;
        const offsetLat = parseFloat(ticket.latitude) + offsetY;
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [offsetLng, offsetLat],
          },
          properties: {
            ...ticket,
            id: index.toString(),
            workticket_type: parseInt(ticket.workticket_type) || 1, // Default to SERVICE type
            status: parseInt(ticket.badge_status) || 0, // Default to DEFAULT status
          },
        };
      })
      .filter(
        (feature) =>
          !isNaN(feature.geometry.coordinates[0]) &&
          !isNaN(feature.geometry.coordinates[1])
      ),
  };
}

// Helper functions
function calculatePitch(zoomLevel, maxPitch) {
  if (zoomLevel < 13) {
    return 0;
  }
  return Math.min(maxPitch, (zoomLevel - 13) * 12);
}

const ICON_TYPES = {
  WORKTICKET: {
    1: "service",
    2: "inspection",
  },
};

const ICON_STATES = {
  DEFAULT: { id: 0, suffix: "" },
  HOVER: { id: 1, suffix: "-hover" },
  ACTIVE: { id: 2, suffix: "-active" },
};

const WORKTICKET_STATUSES = {
  DEFAULT: { id: 0, name: "default" },
  NOT_STARTED: { id: 1, name: "notstarted" },
  IN_PROGRESS: { id: 2, name: "inprogress" },
  OVERDUE: { id: 3, name: "overdue" },
  NON_COMPLIANT: { id: 4, name: "noncompliant" },
  ON_TIME: { id: 5, name: "ontime" },
  ON_TIME_ONSITE: { id: 6, name: "ontimeonsite" },
  FULLY_COMPLIANT: { id: 7, name: "fullycompliant" },
};

function loadMapImages(map) {
  const loadImage = (iconName, image) => {
    map.loadImage(image, (error, img) => {
      if (error) {
        console.error(`Failed to load map icon ${iconName}:`, error);
        return;
      }
      map.addImage(iconName, img);
    });
  };

  const loadSimpleIcons = () => {
    const simpleIcons = {
      location: ["active", "normal", "hover"],
      partner: ["active", "normal", "hover"],
    };

    Object.entries(simpleIcons).forEach(([type, states]) => {
      const prefix = type.slice(0, 3);
      states.forEach((state) => {
        loadImage(`${prefix}-${state}`, mapIcons[type][state]);
      });
    });
  };

  const loadWorkticketIcons = () => {
    Object.entries(ICON_TYPES.WORKTICKET).forEach(([typeId, typeName]) => {
      Object.values(WORKTICKET_STATUSES).forEach((status) => {
        Object.values(ICON_STATES).forEach((state) => {
          const iconName = `wt-${typeName}-${status.name}${state.suffix}`;
          loadImage(iconName, mapIcons[typeId][status.id][state.id]);
        });
      });
    });
  };

  loadSimpleIcons();
  loadWorkticketIcons();
}

function randomOffset(radius) {
  const angle = Math.random() * 2 * Math.PI;
  const r = radius * Math.sqrt(Math.random());
  return [r * Math.cos(angle), r * Math.sin(angle)];
}
