import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Typography, Box, Button } from "@material-ui/core";
import SearchableFilter from "./searchableFilter";
import { useFetchMapFilters } from "./useFetchMapFilters";
import { formatStringReadable } from "pages/locationDetails/utils";
import { countryStates } from "../../../constants";
import GlobalUiContext from "contexts/globalUiContext";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 360,
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(2),
    "& .MuiAccordion-root": {
      boxShadow: "none",
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      "&::before": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const MapFilterDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const {
    data: { filters },
  } = useFetchMapFilters();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const sortedUsers = users.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const states = countryStates.map((state) => ({
    id: state.value,
    name: state.label,
  }));

  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    if (
      Object.keys(selectedItems).length === 0 &&
      Object.keys(filters).length > 0
    ) {
      setSelectedItems(
        Object.keys(filters).reduce(
          (acc, filterName) => ({ ...acc, [filterName]: [] }),
          {}
        )
      );
    }
  }, [filters, selectedItems]);

  const handleItemSelect = (filterId) => (newItem) => {
    if (!selectedItems[filterId].includes(newItem)) {
      setSelectedItems({
        ...selectedItems,
        [filterId]: [...selectedItems[filterId], newItem],
      });
    }
  };

  const handleItemToggle = (filterId) => (itemToRemove) => {
    setSelectedItems({
      ...selectedItems,
      [filterId]: selectedItems[filterId].filter(
        (item) => item !== itemToRemove
      ),
    });
  };

  const sanitizeFilterOptions = (options) => {
    return options.map((option) => ({
      id: option.id,
      name:
        option.name ||
        option.label ||
        `${option.first_name} ${option.last_name}` ||
        option.title,
    }));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.header}>
        <Typography variant="h6">Filters</Typography>
        <Box className={classes.buttonsContainer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            Apply
          </Button>
        </Box>
      </Box>

      <Box className={classes.content}>
        {Object.keys(filters).map((filterName) => {
          const readableFilterName = formatStringReadable(filterName);
          if (filterName === "assigned_to") {
            filters[filterName] = sortedUsers;
          }
          if (filterName === "states") {
            filters[filterName] = states;
          }
          const options = sanitizeFilterOptions(filters[filterName]);
          return (
            <SearchableFilter
              key={filterName}
              title={readableFilterName}
              options={options}
              selectedItems={selectedItems[filterName]}
              onItemSelect={handleItemSelect(filterName)}
              onItemToggle={handleItemToggle(filterName)}
              placeholder={""}
            />
          );
        })}
      </Box>
    </Drawer>
  );
};

export default MapFilterDrawer;
