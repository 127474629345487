import React, { useMemo } from "react";
import Box from "@material-ui/core/Typography";
import Typography from "@material-ui/core/Typography";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { makeStyles } from "@material-ui/core/styles";

const COLORS_DEFAULT = ["#328DC6", "#E2EEF8"];

const useStyles = makeStyles((theme) => ({
  pieRoot: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  pieRootMd: {
    position: "relative",
    marginLeft: "10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  pieRootLg: {
    position: "relative",
    marginLeft: "10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  pieValue2: {
    top: 120,
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
}));

const ChartPieDynamicPct = (props) => {
  const classes = useStyles();
  const {
    pct,
    cx,
    cy,
    height,
    innerRadius,
    outerRadius,
    meta,
    colors = COLORS_DEFAULT,
    CustomTooltip,
  } = props;

  const data = useMemo(() => {
    return [
      { name: "Value", value: pct, meta: meta },
      {
        name: "Total",
        value: 100 - pct,
        meta: meta,
      },
    ];
  }, [pct, meta]);

  return (
    <Box className={classes.pieRoot} component="div">
      <Typography variant="h1" className={classes.pieValue2}>
        {pct.toFixed(0) < 100 ? pct.toFixed(0) : 100}%
      </Typography>

      <ResponsiveContainer width="100%" height={height ?? 220}>
        <PieChart>
          <Tooltip
            contentStyle={{ backgroundColor: "#ffffff" }}
            labelStyle={{ color: "#ffffff" }}
            content={CustomTooltip ? <CustomTooltip /> : null}
            cursor={{ fill: "transparent" }}
          />
          <Pie
            data={data}
            cx={cx ?? 100}
            cy={cy ?? 100}
            innerRadius={innerRadius ?? 80}
            outerRadius={outerRadius ?? 100}
            dataKey="value"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  colors
                    ? colors[index % colors.length]
                    : colors[index % colors.length]
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartPieDynamicPct;
