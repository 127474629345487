import { TablePagination, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    width: "100%",
  },
  pagination: {
    backgroundColor: "#F8F8F8",
    height: 40,
    minHeight: 0,
    padding: "0 16px",
  },
  caption: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  actions: {
    maxHeight: "100%",
    display: "flex",
    gap: 8,
    "& > button:not(.Mui-disabled)": {
      "& svg": {
        fill: theme.palette.secondary.main,
      },
    },
    "& > *": {
      height: "24px",
      width: "24px",
      "&.Mui-selected": {
        color: theme.palette.secondary.main,
      },
    },
  },
}));

export const PaginationBar = ({ data, pagination, onPageChange, children }) => {
  const classes = useStyles();

  const handleChangePage = (_, newPage) => {
    onPageChange(newPage + 1);
  };

  return (
    <Box className={classes.root}>
      {children(data)}
      <TablePagination
        classes={{
          toolbar: classes.pagination,
          caption: classes.caption,
          actions: classes.actions,
        }}
        component="div"
        count={pagination.totalItems}
        page={pagination.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.itemsPerPage}
        rowsPerPageOptions={[]}
      />
    </Box>
  );
};
