import { Box, Typography, makeStyles } from "@material-ui/core";
import { SectionHeaderFilters } from "./elements";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0.1px",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      fontWeight: 700,
    },
  },
}));

export const SectionHeader = (props) => {
  const classes = useStyles();
  const { title, actions } = props;

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {!!actions?.length && (
        <SectionHeaderFilters section={title} actions={actions} />
      )}
    </Box>
  );
};
