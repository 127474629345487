import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  SendRounded as SendIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingBottom: 0,
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(),
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  titleIcon: {
    marginBottom: theme.spacing(1),
    fontSize: "2rem",
    color: "#4D4D4D",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    textAlign: "center",
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    padding: theme.spacing(1, 3),
    minWidth: 120,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    fontWeight: 600,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  textFieldContainer: {
    position: "relative",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[300],
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  sendIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(1),
    color: theme.palette.grey[400],
    cursor: "pointer",
    fontSize: "2rem",
    padding: theme.spacing(0.5),
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const CommentDialog = ({ open, onClose, title, subtitle, onSubmit, icon }) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (open) {
      setComment(""); // Reset comment when dialog opens
    }
  }, [open]);

  const handleSubmit = () => {
    if (comment.trim()) {
      onSubmit(comment);
      handleClose();
    }
  };

  const handleClose = () => {
    setComment(""); // Reset comment when dialog closes
    onClose();
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Box className={classes.titleContainer}>
          {icon}
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.subtitle} gutterBottom>
          {subtitle}
        </Typography>
        <div className={classes.textFieldContainer}>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            placeholder="Add a comment"
            type="text"
            fullWidth
            multiline
            minRows={4}
            variant="outlined"
            value={comment}
            onChange={handleChange}
            className={classes.textField}
          />
          <SendIcon fontSize="inherit" className={classes.sendIcon} />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleClose}
          className={`${classes.button} ${classes.cancelButton}`}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          className={`${classes.button} ${classes.submitButton}`}
          variant="contained"
          disabled={!comment.trim()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;
