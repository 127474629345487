import { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  TextField,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { stateIcons } from "./stateIcons";
import { countryStates } from "../../../constants";

const ITEMS_PER_PAGE = 9;

const StateFilter = ({ selectedStates = [], onStateSelect }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      maxWidth: 400,
      backgroundColor: "#ffffff",
    },
    title: {
      marginBottom: theme.spacing(1),
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.primary,
      paddingLeft: 8,
    },
    searchField: {
      marginBottom: theme.spacing(2),
      "& .MuiOutlinedInput-root": {
        borderRadius: 24,
        backgroundColor: "#ffffff",
        "& fieldset": {
          borderColor: theme.palette.divider,
        },
        "&:hover fieldset": {
          borderColor: theme.palette.divider,
        },
      },
      "& .MuiInputBase-input": {
        padding: theme.spacing(1.5, 2),
      },
    },
    gridContainer: {
      marginBottom: theme.spacing(2),
    },
    selectedCard: {
      backgroundColor: "#ffffff",
      borderRadius: 12,
      boxShadow: "none",
    },
    card: {
      backgroundColor: "#ffffff",
      borderRadius: 12,
      boxShadow: "none",
    },
    cardAction: {
      padding: theme.spacing(1),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    stateIconContainer: {
      width: "100%",
      aspectRatio: "1",
      //   backgroundColor: '#f8fafc',
      borderRadius: 8,
      //   padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    stateIcon: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      borderRadius: 8,
      border: (props) =>
        props.isSelected ? `1px solid #4F98BC` : "1px solid #e0e0e0",
      transition: "border-color 0.2s ease",
    },
    stateName: {
      fontSize: 12,
      color: theme.palette.text.main,
      textAlign: "center",
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: "#f8fafc",
      padding: theme.spacing(1.5, 2),
      borderRadius: 8,
      "& .MuiPagination-ul": {
        "& .MuiPaginationItem-root": {
          minWidth: 32,
          height: 32,
        },
        // Updated selector to catch all numbered page buttons
        '& button[aria-label*="Go to page"], & button[aria-label="page 1"], & button[aria-label="page 6"]':
          {
            display: "none",
          },
        "& .MuiPaginationItem-ellipsis": {
          display: "none",
        },
      },
    },
    paginationText: {
      color: theme.palette.text.secondary,
      fontSize: "0.875rem",
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const states = countryStates.map((state) => ({
    id: state.value,
    name: state.label,
  }));

  const filteredStates = states.filter((state) =>
    state.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredStates.length / ITEMS_PER_PAGE);
  const displayedStates = filteredStates.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const handleStateClick = (stateId) => {
    if (selectedStates.includes(stateId)) {
      onStateSelect(selectedStates.filter((id) => id !== stateId));
    } else {
      onStateSelect([...selectedStates, stateId]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by State
      </Typography>

      <TextField
        fullWidth
        placeholder="Find State"
        value={searchTerm}
        onChange={handleSearchChange}
        className={classes.searchField}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
        }}
      />

      <Grid container spacing={1} className={classes.gridContainer}>
        {displayedStates.map((state) => (
          <Grid item xs={4} key={state.id}>
            <Card className={classes.card}>
              <CardActionArea
                onClick={() => handleStateClick(state.id)}
                className={classes.cardAction}
              >
                <Box className={classes.stateIconContainer}>
                  <img
                    src={stateIcons[state.id]}
                    alt={`${state.name} icon`}
                    className={classes.stateIcon}
                    style={{
                      borderColor: selectedStates.includes(state.id)
                        ? "#4F98BC"
                        : "#e0e0e0",
                    }}
                  />
                </Box>
                <Typography className={classes.stateName}>
                  {state.name}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box className={classes.paginationContainer}>
        <Typography className={classes.paginationText}>
          {`${(page - 1) * ITEMS_PER_PAGE + 1}-${Math.min(
            page * ITEMS_PER_PAGE,
            filteredStates.length
          )} of ${filteredStates.length}`}
        </Typography>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="small"
          siblingCount={0}
          boundaryCount={0}
          showFirstButton={false}
          showLastButton={false}
        />
      </Box>
    </Box>
  );
};

export default StateFilter;
