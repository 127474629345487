import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ChartPieDynamicPct from "components/common/Charts/ChartPieDynamicPct";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
    borderRadius: 4,
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    minWidth: 300,
    width: "fit-content",
  },
}));

export const PieChart = () => {
  const classes = useStyles();
  const { metrics: { isLoading, data: { metrics = {} } = {} } = {} } =
    useLocationDetailsContext();

  return (
    <Box className={classes.root}>
      <Typography className={classes.chartLabel}>WT Liquidity</Typography>
      {!isLoading && metrics?.liquidity && (
        <ChartPieDynamicPct
          pct={metrics.liquidity}
          cx="50%"
          cy={150}
          innerRadius={105}
          outerRadius={130}
          height={300}
          colors={["#9CBB65", "#EAEAEA"]}
        />
      )}
      {isLoading && <Skeleton height={300} />}
    </Box>
  );
};
