import clipboardChecklist from "./clipboard-checklist.png";

export const ClipboardChecklist = ({
  className,
  width = "auto",
  height = "auto",
}) => (
  <img
    className={className}
    src={clipboardChecklist}
    alt="Clipboard Checklist Illustration"
    width={width}
    height={height}
  />
);
