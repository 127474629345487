import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useCreateLocationComment = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) =>
      http.post(`${apiUrl}/locations/${locationId}/comment`, data),
    onMutate: async (newComment) => {
      const user = JSON.parse(localStorage.getItem("user"));

      await queryClient.cancelQueries(["locationComments", locationId]);

      const previousComments = queryClient.getQueryData([
        "locationComments",
        locationId,
      ]);

      queryClient.setQueryData(["locationComments", locationId], (old) => {
        if (!old?.data?.data) return old;

        const updatedComments = { ...old };
        const tab = newComment.tab;

        const typeMap = {
          internal: "1",
          partner: "2",
          customer: "3",
        };

        const newCommentObj = {
          body: newComment.body,
          created_at: new Date()
            .toISOString()
            .replace("T", " ")
            .replace("Z", ""),
          description: null,
          display_name: null,
          external_id: null,
          file_created_at: null,
          file_id: null,
          file_size: null,
          file_url: null,
          id: `temp-${Date.now()}`,
          mime_type: null,
          module_type: "3",
          profile_url: user.profile.picture,
          type: typeMap[tab],
          user_id: user.id,
          user_name: `${user.first_name} ${user.last_name}`,
          video_metadata: null,
          workticket_id: null,
        };

        updatedComments.data.data[tab].comments = [
          newCommentObj,
          ...(updatedComments.data.data[tab].comments || []),
        ];

        return updatedComments;
      });

      return { previousComments };
    },
    onError: (_, __, context) => {
      if (context?.previousComments) {
        queryClient.setQueryData(
          ["locationComments", locationId],
          context.previousComments
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries(["locationComments", locationId]),
  });
};
