import { makeStyles, Avatar as MuiAvatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "25px",
    height: "25px",
    [`@media (min-width: 768px) and (max-width: 959px)`]: {
      width: "21px",
      height: "21px",
    },
    [`@media (min-width: 960px) and (max-width: 1095px)`]: {
      width: "25px",
      height: "25px",
    },
    [`@media (min-width: 1096px)`]: {
      width: "21px",
      height: "21px",
    },
    [`@media (min-width: 1280px)`]: {
      width: "25px",
      height: "25px",
    },
  },
}));

export const Avatar = ({ avatar }) => {
  const classes = useStyles();

  return <MuiAvatar className={classes.avatar} src={avatar} />;
};
