import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useState } from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import {
  MobileImageCarousel,
  LocationInformation,
  Divider,
} from "pages/locationDetails/components";
import {
  RecentInspections,
  CompletedWorktickets,
  LocationInsights,
  ReactiveRequests,
  KeyMetrics,
  Comments,
  RecentSurveys,
} from "pages/locationDetails/sections";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #ECECEC",
    minHeight: 0,
    overflowX: "auto",
    display: "flex",
    flexShrink: 0,
    width: "auto",
    "& .MuiTabs-scroller": {
      padding: "0 8px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    padding: "0 8px",
    "& > span": {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
    },
  },
}))(MuiTabs);

const StyledTab = withStyles((theme) => ({
  root: {
    minHeight: 0,
    minWidth: 0,
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    padding: "8px",
    margin: 0,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}))((props) => <MuiTab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    overflowX: "hidden",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.only("sm")]: {
      marginLeft: 8,
    },
  },
}));

export const MobileLayout = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Overview", "Insights", "Key Metrics", "Comments"];

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <RecentInspections />
            <Divider />
            <CompletedWorktickets />
            <Divider />
            <ReactiveRequests />
            <Divider />
            <RecentSurveys />
          </>
        );
      case 1:
        return <LocationInsights />;
      case 2:
        return <KeyMetrics />;
      case 3:
        return <Comments />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.root}>
      <MobileImageCarousel />
      <LocationInformation />
      <StyledTabs
        value={activeTab}
        onChange={handleChange}
        aria-label="mobile tabs"
        variant="scrollable"
        scrollButtons="off"
        TabIndicatorProps={{ children: <span /> }}
      >
        {tabs.map((tab) => (
          <StyledTab key={tab} label={tab} />
        ))}
      </StyledTabs>
      <Box
        style={{
          padding: "20px 16px 48px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderTabContent()}
      </Box>
    </Box>
  );
};
