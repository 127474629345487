import {
  useTheme,
  useMediaQuery,
  Divider as MuiDivider,
} from "@material-ui/core";

export const Divider = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiDivider
      style={{
        backgroundColor: "#ECECEC",
        margin: isMobile ? "24px 0" : "40px 0",
      }}
    />
  );
};
