import React from "react";

export const WorkticketTriangleIcon = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.77637 8.60893C6.89514 8.82642 7.19149 8.82642 7.30969 8.60893L7.40401 8.43625L7.45932 8.33467C7.45932 8.33467 8.32625 6.74588 8.33033 6.73811C8.87005 5.74863 9.41909 4.76094 9.97046 3.77623C10.2371 3.29942 10.6022 2.71624 10.6103 2.14562C10.6179 1.62279 10.3565 1.07965 9.95765 0.75759C9.57047 0.44509 9.12856 0.20489 8.64939 0.0561085C8.58825 0.036988 8.52595 0.0190626 8.46366 0.00292969H11.5C11.769 0.00292969 12.0205 0.056706 12.2481 0.152308C13.3316 0.608212 13.8801 2.02193 13.2408 3.19425C13.2408 3.19425 11.362 6.63773 11.3305 6.69569C10.6802 7.88773 10.1242 9.27575 8.90557 9.96469C8.69597 10.083 8.46599 10.1822 8.22727 10.1738C7.8564 10.1607 7.67358 9.84339 7.47213 9.57152C7.43486 9.52133 7.39818 9.46995 7.36209 9.41856C7.30619 9.33909 7.24215 9.26978 7.18975 9.18732C7.17636 9.16641 7.16821 9.15386 7.1554 9.13295"
      fill="url(#paint0_linear_411_6809)"
    />
    <path
      d="M10.3925 2.98578C10.3651 2.87584 10.2714 2.82624 10.1642 2.79876C9.90398 2.73184 9.56105 2.788 9.29381 2.788H6.17424C5.00572 2.788 3.79469 2.68762 2.64014 2.88958C2.40666 2.93021 2.16562 2.99056 1.98338 3.14591C1.78892 3.31202 1.68528 3.57134 1.66025 3.83007C1.60727 4.37201 1.79241 5.05437 2.02123 5.53956C1.9304 5.18822 1.65676 4.81298 1.48791 4.48793C1.30102 4.12703 1.11412 3.76554 0.927227 3.40464C0.716461 2.99713 0.500456 2.5717 0.487064 2.11042C0.45446 0.968571 1.51004 0 2.58075 0H8.44667C8.5113 0.0161329 8.57476 0.0352533 8.63822 0.0531788C9.15175 0.196582 9.55057 0.442161 9.9494 0.754661C10.5037 1.18905 10.7325 1.92519 10.5462 2.5962C10.5415 2.61293 10.5363 2.62966 10.531 2.64699C10.5089 2.71869 10.4821 2.7892 10.4501 2.85851L10.4455 2.86986C10.4437 2.87404 10.4414 2.87823 10.439 2.88301"
      fill="url(#paint1_linear_411_6809)"
    />
    <path
      d="M10.2222 8.74261C9.71332 9.43214 9.26676 10.1814 8.79049 10.8961L8.74916 10.9672C7.95092 12.3438 5.95622 12.3438 5.15799 10.9672L4.66135 10.1109L2.76388 6.83774L2.4128 6.23245C2.05822 5.6206 1.75837 5.03145 1.66522 4.31324C1.62621 4.0103 1.62388 3.68585 1.77118 3.42055C1.91033 3.1696 2.11004 3.00468 2.43783 2.92282C2.99269 2.7848 3.65177 2.76448 4.21886 2.76448C4.05292 2.75851 3.81887 2.78181 3.73852 2.96525C3.67622 3.10745 3.75599 3.26878 3.83401 3.40263C4.50764 4.56121 5.18011 5.72038 5.852 6.88016C5.852 6.88016 6.74222 8.41577 6.74281 8.41697C6.74514 8.42115 6.74688 8.42533 6.74979 8.42951C6.76726 8.46238 6.78531 8.49524 6.80336 8.52751C6.88603 8.67509 6.97395 8.81909 7.06711 8.95891C7.28661 9.29113 7.50028 9.64247 7.84321 9.85638C8.13607 10.0386 8.50695 10.1079 8.82892 9.98664C8.96924 9.93346 9.09558 9.84742 9.2161 9.75659C9.59571 9.47038 9.93515 9.12801 10.221 8.74321L10.2222 8.74261Z"
      fill="url(#paint2_linear_411_6809)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_411_6809"
        x1="9.16873"
        y1="-1.56734"
        x2="10.8593"
        y2="9.70777"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stopColor="#80CEF1" />
        <stop offset="0.21" stopColor="#79C6EB" />
        <stop offset="0.35" stopColor="#68B0DA" />
        <stop offset="0.52" stopColor="#4D8EC0" />
        <stop offset="0.72" stopColor="#265E9B" />
        <stop offset="0.78" stopColor="#194D8E" />
        <stop offset="1" stopColor="#0D152E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_411_6809"
        x1="0.528402"
        y1="2.97801"
        x2="10.6295"
        y2="1.81588"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="#6EC4EB" />
        <stop offset="0.2" stopColor="#65BEE8" />
        <stop offset="0.36" stopColor="#4CB1E1" />
        <stop offset="0.55" stopColor="#299DD7" />
        <stop offset="0.62" stopColor="#2691CB" />
        <stop offset="0.74" stopColor="#2072AC" />
        <stop offset="0.86" stopColor="#194E88" />
        <stop offset="1" stopColor="#0D152E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_411_6809"
        x1="8.54887"
        y1="11.3759"
        x2="2.37991"
        y2="1.16675"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stopColor="#D5DD28" />
        <stop offset="0.23" stopColor="#B8C828" />
        <stop offset="0.46" stopColor="#6E942A" />
        <stop offset="0.72" stopColor="#17562C" />
        <stop offset="0.75" stopColor="#174925" />
        <stop offset="0.82" stopColor="#173A1D" />
        <stop offset="0.89" stopColor="#173118" />
        <stop offset="1" stopColor="#172F17" />
      </linearGradient>
    </defs>
  </svg>
);
