import { Box, makeStyles } from "@material-ui/core";
import { FilterProvider } from "contexts/filterContext";
import { Skeleton } from "@material-ui/lab";
import {
  SectionHeader,
  LocationInsightCard,
  PaginationBar,
  CardGrid,
  ZeroState,
} from "pages/locationDetails/components";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import { ClipboardMagnifyingGlass } from "assets/illustrations";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "100%",
    minWidth: 0,
  },
}));

const CardsSkeleton = ({ count = 6 }) => {
  return (
    <CardGrid>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            height={143}
            style={{
              borderRadius: 16,
              animationDelay: `${index * 30}ms`,
            }}
          />
        ))}
    </CardGrid>
  );
};

export const LocationInsights = () => {
  const classes = useStyles();
  const {
    insights: {
      isLoading,
      isFetching,
      data: { cardData, pagination },
    },
  } = useLocationDetailsContext();
  const dispatch = useLocationDetailsDispatch();

  const handlePageChange = (newPage) => {
    dispatch({ type: "SET_LOCATION_INSIGHTS_PAGE", page: newPage });
  };

  return (
    <FilterProvider>
      <Box className={classes.root}>
        <SectionHeader title="Location Insights" actions={["filter"]} />
        {isLoading || isFetching ? (
          <PaginationBar
            data={cardData}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {() => <CardsSkeleton />}
          </PaginationBar>
        ) : cardData.length > 0 ? (
          <PaginationBar
            data={cardData}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {(currentItems) => (
              <CardGrid>
                {currentItems.map((card) => (
                  <LocationInsightCard key={card.id} {...card} />
                ))}
              </CardGrid>
            )}
          </PaginationBar>
        ) : (
          <ZeroState
            illustration={<ClipboardMagnifyingGlass height={69} />}
            text="No insights run yet"
          />
        )}
      </Box>
    </FilterProvider>
  );
};
