import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationComments = (
  queryStrings,
  activeTab = "internal"
) => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationComments", locationId, queryStrings, activeTab],
    queryFn: () => {
      const requests = {
        internal: http.get(
          `${apiUrl}/locations/${locationId}/comments${queryStrings.internal}`
        ),
        partner: http.get(
          `${apiUrl}/locations/${locationId}/comments${queryStrings.partner}`
        ),
        customer: http.get(
          `${apiUrl}/locations/${locationId}/comments${queryStrings.customer}`
        ),
      };

      return requests[activeTab];
    },
    enabled: !!locationId,
    keepPreviousData: true,
    select: (data) => {
      const comments = data?.data?.data;
      const { internal, partner, customer } = comments || {};
      const { comments: internalComments, ...internalPagination } =
        internal || {};
      const { comments: partnerComments, ...partnerPagination } = partner || {};
      const { comments: customerComments, ...customerPagination } =
        customer || {};

      const sortByDate = (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

      const tabData = {
        internal: {
          cardData: internalComments?.sort(sortByDate),
          pagination: {
            currentPage: internalPagination?.current_page,
            itemsPerPage: internalPagination?.items_per_page,
            totalItems: internalPagination?.total_items,
            totalPages: internalPagination?.total_pages,
          },
        },
        partner: {
          cardData: partnerComments?.sort(sortByDate),
          pagination: {
            currentPage: partnerPagination?.current_page,
            itemsPerPage: partnerPagination?.items_per_page,
            totalItems: partnerPagination?.total_items,
            totalPages: partnerPagination?.total_pages,
          },
        },
        customer: {
          cardData: customerComments?.sort(sortByDate),
          pagination: {
            currentPage: customerPagination?.current_page,
            itemsPerPage: customerPagination?.items_per_page,
            totalItems: customerPagination?.total_items,
            totalPages: customerPagination?.total_pages,
          },
        },
      };

      return tabData;
    },
  });
};
