import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useUploadLocationImages = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const formData = new FormData();

      formData.append("type", "file");

      for (let file of data) {
        formData.append("files[]", file);
        formData.append("file_name", file.name);
        formData.append("file_description", "File description here");
      }

      return await http.post(
        `${apiUrl}/locations/${locationId}/upload-files`,
        formData
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["locationFiles"] }),
  });
};
