import { Box, Typography, makeStyles } from "@material-ui/core";
import * as classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 8,
  },
  text: {
    maxWidth: 170,
    fontSize: 14,
    fontWeight: 600,
  },
  subText: {
    maxWidth: 260,
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const ZeroState = ({ className, illustration, text, subText }) => {
  const classes = useStyles();

  return (
    <Box className={classNames(classes.root, className)}>
      {!!illustration && illustration}
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>{text}</Typography>
        {!!subText && (
          <Typography className={classes.subText}>{subText}</Typography>
        )}
      </Box>
    </Box>
  );
};
