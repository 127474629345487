import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";

export const useUpdateLocationComment = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ commentId, body }) =>
      http.put(`${apiUrl}/locations/${locationId}/comments/${commentId}`, body),
    onMutate: async ({ commentId, body }) => {
      await queryClient.cancelQueries(["locationComments", locationId]);

      const previousComments = queryClient.getQueryData([
        "locationComments",
        locationId,
      ]);

      queryClient.setQueryData(["locationComments", locationId], (old) => {
        if (!old?.data?.data) return old;

        const updatedComments = { ...old };
        const tab = body.tab;

        updatedComments.data.data[tab].comments = updatedComments.data.data[
          tab
        ].comments.map((comment) =>
          comment.id === commentId ? { ...comment, body: body.body } : comment
        );

        return updatedComments;
      });

      return { previousComments };
    },
    onError: (_, __, context) => {
      if (context?.previousComments) {
        queryClient.setQueryData(
          ["locationComments", locationId],
          context.previousComments
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries(["locationComments", locationId]),
  });
};
