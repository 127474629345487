import { Box, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gap: 20,
    gridTemplateColumns: `repeat(auto-fit, minmax(min(100%, 330px), 1fr))`,
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(min(100%, 380px), 1fr))`,
    },
  },
  placeholder: {
    visibility: "hidden",
    pointerEvents: "none",
  },
}));

export const CardGrid = ({ children, debug = false }) => {
  const classes = useStyles();
  const gridRef = useRef(null);
  const [layout, setLayout] = useState({ columns: 0, rows: 0 });
  const childrenCount = Array.isArray(children) ? children.length : 1;

  useEffect(() => {
    if (!gridRef.current?.children.length) return;

    const updateLayout = () => {
      const computedStyle = window.getComputedStyle(gridRef.current);
      const columns = computedStyle.gridTemplateColumns.split(" ").length;
      const rows = Math.ceil(6 / columns);
      setLayout({ columns, rows });
    };

    const resizeObserver = new ResizeObserver(updateLayout);
    resizeObserver.observe(gridRef.current);
    updateLayout();

    return () => resizeObserver.disconnect();
  }, []);

  const getPlaceholderCount = () => {
    const { columns } = layout;
    if (!columns) return 0;

    if (columns === 1) return 0;

    const itemsInLastRow = childrenCount % columns;
    if (itemsInLastRow === 0) return 0;

    return columns - itemsInLastRow;
  };

  const placeholders = Array(getPlaceholderCount())
    .fill(null)
    .map((_, index) => (
      <Box
        key={`placeholder-${index}`}
        className={classes.placeholder}
        style={{ height: gridRef.current?.children[0]?.offsetHeight }}
      />
    ));

  return (
    <Box ref={gridRef} className={classes.root}>
      {children}
      {placeholders}
    </Box>
  );
};
