import {
  makeStyles,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  ExclamationTriangleIcon,
  FlagIcon,
  ShieldExclamationIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid";
import { CardChip } from "pages/locationDetails/components/cards/commonElements";
import { ViewButton } from "pages/locationDetails/components";
import { InspectionMagnifyingGlassIcon } from "assets/icons/inspection-magnifying-glass-icon";
import { WorkticketTriangleIcon } from "assets/icons/workticket-triangle-icon";
import { WarningBadgeIcon } from "assets/icons/warningBadgeIcon";
import { ClipboardDocumentAlertIcon } from "assets/icons/clipboardDocumentAlertIcon";
import { formatDate } from "pages/locationDetails/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "16px 12px",
    borderRadius: 4,
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    backgroundColor: theme.palette.background.default,
    width: "100%",
    boxSizing: "border-box",
    minWidth: 0,
    border: 0,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
    },
    [`@media (min-width: 1096px)`]: {
      padding: 16,
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    maxWidth: "100%",
    width: "100%",
    minWidth: 0,
  },
  mainRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: 16,
    maxWidth: "100%",
    width: "100%",
    minWidth: 0,
  },
  rowGroupSmall: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  rowGroupLarge: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    minWidth: 0,
  },
  insightTypeIconContainer: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px solid #BAE7FA",
    borderRadius: "100%",
    backgroundColor: "#EDF5F8",
  },
  insightTypeIcon: {
    height: 12,
  },
  workticketNumber: {
    fontSize: 12,
    letterSpacing: "0.4px",
    lineHeight: "16px",
    fontWeight: 600,
  },
  date: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: "0.1px",
    lineHeight: "19px",
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: "0.1px",
    lineHeight: "20px",
    color: "#4D4D4D",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textAlign: "left",
  },
  bottomBar: {
    height: 40,
    padding: "0 8px",
    backgroundColor: "#F8F8F8",
    borderRadius: 4,
    width: "100%",
    boxSizing: "border-box",
    minWidth: 0,
    marginTop: "auto",
  },
  fileCount: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
    flexShrink: 0,
  },
  categories: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flex: 1,
  },
  iconContainer: {
    height: 24,
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 50,
    "& svg": {
      color: "inherit",
      width: 16,
      height: 16,
    },
  },
  viewButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const LocationInsightCard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    id,
    workticket_id,
    created_at: createdAt,
    name,
    number,
    status,
    total_media: totalMedia,
    type,
    categories,
  } = props;

  const categoriesString = categories
    .map((category) => category.name)
    .join(", ");

  const statusChipMap = {
    0: {
      label: "In Progress",
      backgroundColor: "#FFF5D2",
      textColor: "#E3B100",
    },
    1: {
      label: "Completed",
      backgroundColor: "#E8F8CB",
      textColor: "#328914",
    },
  };

  const iconMap = (iconColor) => ({
    shieldExclamation: <ShieldExclamationIcon color={iconColor} />,
    checkBadge: <WarningBadgeIcon color={iconColor} />,
    flag: <FlagIcon color={iconColor} />,
    documentPlus: <DocumentPlusIcon color={iconColor} />,
    exclamationTriangle: <ExclamationTriangleIcon color={iconColor} />,
    clipboardDocumentCheck: <ClipboardDocumentAlertIcon color={iconColor} />,
  });

  return (
    <Box
      component={isMobile ? "button" : "div"}
      className={classes.root}
      {...(isMobile
        ? {
            onClick: () => history.push(`/surveys/completed/${id}`),
            type: "button",
          }
        : {})}
    >
      <Box className={classes.row}>
        <Box className={classes.rowGroupSmall}>
          {["1", "2"].includes(type) && (
            <Box className={classes.insightTypeIconContainer}>
              {type === "1" ? (
                <WorkticketTriangleIcon className={classes.insightTypeIcon} />
              ) : (
                <InspectionMagnifyingGlassIcon
                  className={classes.insightTypeIcon}
                />
              )}
            </Box>
          )}
          <Typography className={classes.workticketNumber}>{number}</Typography>
        </Box>
        <Box className={classes.rowGroupLarge}>
          <Typography className={classes.date}>
            {formatDate(createdAt)}
          </Typography>
          <Box className={classes.viewButtonContainer}>
            <ViewButton
              onClick={() =>
                history.push(
                  `/lens-analysis/${workticket_id}/${number}/${id}/edit/none`
                )
              }
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.mainRow}>
        <Typography className={classes.name}>{name}</Typography>
        <CardChip
          label={statusChipMap[status].label}
          backgroundColor={statusChipMap[status].backgroundColor}
          textColor={statusChipMap[status].textColor}
        />
      </Box>
      <Box className={`${classes.row} ${classes.bottomBar}`}>
        <Box className={classes.rowGroupLarge}>
          <Typography className={classes.categories}>
            {categoriesString}
          </Typography>
          <Box className={classes.rowGroupSmall}>
            {categories.map((category) => (
              <Box
                key={category.id}
                className={classes.iconContainer}
                style={{
                  backgroundColor: category.background_color,
                  color: category.icon_color,
                  borderColor: category.icon_color,
                }}
              >
                {iconMap(category.icon_color)[category.icon]}
              </Box>
            ))}
          </Box>
        </Box>
        <Typography className={classes.fileCount}>
          {`${totalMedia} Files`}
        </Typography>
      </Box>
    </Box>
  );
};
