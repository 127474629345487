import { makeStyles, Chip } from "@material-ui/core";
import { TradeIconSet } from "components/ui/Trades";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 32,
    gap: 8,
    borderRadius: 50,
    backgroundColor: theme.palette.background.default,
    border: "1px solid #ECECEC",
    padding: "0 8px",
  },
  icon: {
    width: 20,
    height: 20,
    flexShrink: 0,
    margin: 0,
  },
  label: {
    fontSize: 14,
    letterSpacing: "0.1px",
    lineHeight: 1,
    fontWeight: 400,
    color: theme.palette.text.primary,
    padding: 0,
  },
}));

export const TradeChip = ({ label }) => {
  const classes = useStyles();

  return (
    <Chip
      classes={{
        root: classes.root,
        icon: classes.icon,
        label: classes.label,
      }}
      icon={TradeIconSet[label]}
      label={label}
    />
  );
};
