import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { DateFilter, GeneralFilters } from "./filters";
import { FilterProvider } from "contexts/filterContext";
import LocationDetailsFilterDrawer from "pages/locationDetails/components/filterDrawer/locationDetailsFilterDrawer";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    height: 28,
    [theme.breakpoints.up("lg")]: {
      gap: 16,
      height: 40,
    },
  },
}));

export const SectionHeaderFilters = ({ actions, section }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderDateAction = !isMobile && actions.includes("date");
  const renderFilterAction = actions.includes("filter");

  return (
    <Box className={classes.root}>
      <FilterProvider>
        {renderDateAction && <DateFilter section={section} />}
        {renderFilterAction && (
          <>
            <GeneralFilters section={section} />
            <LocationDetailsFilterDrawer section={section} />
          </>
        )}
      </FilterProvider>
    </Box>
  );
};
