import React, { useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocationDetailsContext } from "pages/locationDetails/context/useLocationDetailsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 250,
    aspectRatio: "45 / 31",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    flexShrink: 0,
  },
  carouselContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  indicatorDotsContainer: {
    position: "absolute",
    display: "flex",
    gap: 8,
    bottom: 16,
    left: "50%",
    transform: "translateX(-50%)",
  },
  indicatorDot: {
    width: 8,
    height: 8,
    borderRadius: "100%",
    backgroundColor: "white",
  },
  inactiveDot: {
    backdropFilter: "blur(4px)",
    boxShadow: "0px 2px 20px 0px rgba(116, 116, 116, 0.25)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  frame: {
    width: "100%",
    height: "100%",
    flexShrink: 0,
    scrollSnapAlign: "center",
    scrollSnapStop: "always",
  },
}));

export const MobileImageCarousel = () => {
  const classes = useStyles();
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { images } = useLocationDetailsContext();

  if (!images?.length) return null;

  const handleScroll = () => {
    const scrollLeft = carouselRef.current.scrollLeft;
    const index = Math.round(scrollLeft / carouselRef.current.offsetWidth);
    setCurrentIndex(index);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={classes.carouselContainer}
        ref={carouselRef}
        onScroll={handleScroll}
      >
        {images.map((image, index) => (
          <Box
            aria-hidden={currentIndex !== index}
            key={image.id}
            className={classes.frame}
          >
            <img
              className={classes.image}
              src={image.file_url}
              alt={`Frame ${image.id}`}
              draggable={false}
            />
          </Box>
        ))}
      </Box>
      <Box className={classes.indicatorDotsContainer}>
        {images.map((image, index) => (
          <Box
            key={image.id}
            className={`${classes.indicatorDot} ${
              currentIndex !== index && classes.inactiveDot
            }`}
          />
        ))}
      </Box>
    </Box>
  );
};
