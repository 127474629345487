import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import {
  Event,
  DescriptionOutlined,
  CheckCircle,
  Archive,
} from "@material-ui/icons";
import { InspectionMagnifyingGlassIcon } from "assets/icons/inspection-magnifying-glass-icon";
import { CardChip } from "pages/locationDetails/components/cards/commonElements";
import { ViewButton } from "pages/locationDetails/components";
import { formatDate } from "pages/locationDetails/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    gap: 12,
    padding: "16px 12px",
    borderRadius: "4px",
    border: 0,
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
    },
    [`@media (min-width: 1096px)`]: {
      padding: 16,
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",
    width: "100%",
    minWidth: 0,
  },
  rowGroup: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    minWidth: 0,
  },
  mainIconContainer: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.5px solid #BAE7FA",
    borderRadius: "100%",
    backgroundColor: "#EDF5F8",
  },
  mainIcon: {
    width: 9,
    height: 12,
  },
  avatar: {
    width: "24px",
    height: "24px",
  },
  workticketNumber: {
    fontSize: 12,
    letterSpacing: "0.4px",
    lineHeight: "16px",
    fontWeight: 600,
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  statusChipAndViewButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: "0.1px",
    lineHeight: "20px",
    color: "#4D4D4D",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  completedDate: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: "10px",
  },
  documentCount: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.4px",
    lineHeight: "16px",
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.text.main,
  },
  viewButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const RecentInspectionCard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    id,
    number,
    name,
    status,
    completed_date,
    documents_available,
    users,
  } = props;
  const avatars = users.map((user) => user.photo);

  const statusMap = {
    2: {
      label: "Done",
      icon: <CheckCircle />,
      backgroundColor: "#328914",
    },
    4: {
      label: "Completed",
      icon: <CheckCircle />,
      backgroundColor: "#9CBB65",
    },
    5: {
      label: "Archived",
      icon: <Archive />,
      backgroundColor: "#4D4D4D",
    },
  };

  return (
    <Box
      component={theme.breakpoints.down("sm") ? "button" : "div"}
      className={classes.root}
      {...(isMobile
        ? {
            onClick: () => history.push(`/surveys/completed/${id}`),
            type: "button",
          }
        : {})}
    >
      <Box className={classes.row}>
        <Box className={classes.rowGroup}>
          <Box className={classes.mainIconContainer}>
            <InspectionMagnifyingGlassIcon className={classes.mainIcon} />
          </Box>
          <AvatarGroup max={3}>
            {avatars.map((avatar, index) => (
              <Avatar key={index} src={avatar} className={classes.avatar} />
            ))}
          </AvatarGroup>
          <Typography className={classes.workticketNumber}>{number}</Typography>
        </Box>
        <Box className={classes.statusChipAndViewButtonContainer}>
          <CardChip
            label={statusMap[status].label}
            icon={statusMap[status].icon}
            textColor={theme.palette.text.inverted}
            backgroundColor={statusMap[status].backgroundColor}
          />
          <Box className={classes.viewButtonContainer}>
            <ViewButton onClick={() => history.push(`/workticket/${id}`)} />
          </Box>
        </Box>
      </Box>
      <Typography className={classes.name}>{name}</Typography>
      <Box className={classes.row}>
        <Box className={classes.rowGroup}>
          <Event className={classes.icon} />
          <Typography className={classes.completedDate}>
            {`Completed: ${formatDate(completed_date)}`}
          </Typography>
        </Box>
        <Box className={classes.rowGroup}>
          <DescriptionOutlined className={classes.icon} />
          <Typography className={classes.documentCount}>
            {`${documents_available} Documents`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
