import carpetIcon from "assets/icons/trades/carpet.png";
import janitorialIcon from "assets/icons/trades/janitorial.png";
import windowIcon from "assets/icons/trades/window.png";
import hvacIcon from "assets/icons/trades/hvac.png";
import paintingIcon from "assets/icons/trades/painting.png";
import pestIcon from "assets/icons/trades/pest.png";
import pressureIcon from "assets/icons/trades/pressure.png";
import plumbingAndElectricalIcon from "assets/icons/trades/plumbing-and-electrical.png";
import floorIcon from "assets/icons/trades/floor.png";
import maintenanceIcon from "assets/icons/trades/maintenance.png";

export const tradeIcons = {
  carpet: carpetIcon,
  janitorial: janitorialIcon,
  window: windowIcon,
  hvac: hvacIcon,
  painting: paintingIcon,
  pest: pestIcon,
  pressure: pressureIcon,
  plumbingAndElectrical: plumbingAndElectricalIcon,
  floor: floorIcon,
  maintenance: maintenanceIcon,
};
