import { Box, Typography, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  label: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 600,
    letterSpacing: "0.5px",
  },
  input: {
    height: 40,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    letterSpacing: "0.25px",
    borderRadius: 4,
    margin: 0,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D9" /* default */,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main /* focused state */,
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D980" /* disabled state */,
    },
    "& .MuiOutlinedInput-input[readonly] ~ .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D980" /* readonly state */,
    },
    "& .MuiOutlinedInput-input[readonly]": {
      cursor: "default",
    },
  },
}));

export const Input = ({ label, value, onChange, readOnly }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        value={value}
        onChange={onChange}
        className={classes.input}
        margin="dense"
        variant="outlined"
        InputProps={{
          readOnly: readOnly,
        }}
      />
    </Box>
  );
};
