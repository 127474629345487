import React from "react";

export const InspectionMagnifyingGlassIcon = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_403_5974)">
      <path
        d="M9.36963 11.2238L9.36846 11.2266C9.36551 11.2345 9.36257 11.2419 9.35963 11.2498C9.35728 11.2555 9.35551 11.2606 9.35316 11.2663C9.34492 11.2867 9.3361 11.3071 9.32668 11.3269C9.3208 11.3394 9.31433 11.3519 9.30785 11.3643C9.30138 11.3768 9.29491 11.3893 9.28785 11.4012C9.28314 11.4091 9.27844 11.4176 9.27373 11.4255C9.26902 11.4335 9.26373 11.4414 9.25902 11.4493C9.25431 11.4573 9.24902 11.4652 9.24372 11.4731C9.23607 11.485 9.22784 11.4964 9.2196 11.5083C9.21136 11.5196 9.20312 11.5309 9.1943 11.5423C9.1943 11.5423 9.19371 11.5434 9.19312 11.544C9.17606 11.5661 9.15782 11.5871 9.13899 11.608C9.11958 11.6296 9.09898 11.6505 9.0778 11.6704C9.06427 11.6834 9.05015 11.6959 9.03603 11.7083C9.03603 11.7083 9.03544 11.7083 9.03485 11.7089C9.02014 11.7214 9.00543 11.7333 8.99072 11.7452C8.98778 11.7475 8.98543 11.7497 8.98248 11.7514C8.97013 11.7611 8.95719 11.7707 8.94365 11.7798C8.88717 11.8194 8.82598 11.8551 8.76067 11.8857C8.17465 12.1595 7.47096 11.9322 7.17325 11.3779L7.14618 11.3241L6.73903 10.5159L6.70137 10.4405C6.55958 10.1588 6.49897 9.85901 6.51133 9.56713C6.51133 9.55749 6.51192 9.54842 6.51251 9.53879C6.51368 9.51895 6.51545 9.49855 6.51721 9.47871C6.51898 9.45718 6.52133 9.43621 6.52427 9.41467C6.52545 9.4039 6.52722 9.39257 6.52898 9.3818C6.53133 9.36706 6.53369 9.35289 6.53604 9.33816C6.53781 9.32682 6.54016 9.31492 6.54251 9.30358C6.54369 9.29622 6.54546 9.28942 6.54663 9.28205C6.54781 9.27638 6.54899 9.27128 6.55016 9.26618C6.55016 9.26448 6.55016 9.26334 6.55075 9.26221C6.55369 9.24918 6.55663 9.23671 6.55958 9.22367C6.63489 8.91422 6.79257 8.62461 7.02145 8.38883C7.10029 8.30722 7.18796 8.23241 7.28327 8.16496C7.32563 8.13493 7.37035 8.10602 7.41624 8.07882C7.45331 8.05671 7.49097 8.03631 7.53039 8.01647C7.68513 7.91672 7.83399 7.80734 7.97578 7.68945L8.05227 7.84078L8.05404 7.84418L8.64182 9.01057L9.29903 10.3147L9.32904 10.3742C9.45965 10.653 9.46554 10.9563 9.36904 11.2255L9.36963 11.2238Z"
        fill="url(#paint0_linear_403_5974)"
      />
      <path
        d="M9.29396 5.81151C9.19923 6.07449 9.08097 6.32386 8.94271 6.5568C8.685 6.99094 8.3561 7.37123 7.97601 7.68748C7.83422 7.80537 7.68536 7.91475 7.53062 8.0145C7.4912 8.03434 7.45354 8.05474 7.41647 8.07685C7.37058 8.10405 7.32645 8.13239 7.2835 8.16299C7.18819 8.23044 7.10111 8.30582 7.02168 8.38686C6.7928 8.6232 6.63453 8.91225 6.55981 9.2217C6.55686 9.23417 6.55333 9.2472 6.55098 9.26024C6.55098 9.26137 6.55039 9.26307 6.5498 9.26421C6.54863 9.26931 6.54745 9.27498 6.54686 9.28064C6.5451 9.28801 6.54392 9.29481 6.54274 9.30218C6.54039 9.31352 6.53804 9.32542 6.53627 9.33675C6.53392 9.35092 6.53156 9.36566 6.52921 9.38039C6.52745 9.39116 6.52568 9.4025 6.5245 9.41327C6.52156 9.4348 6.51921 9.45577 6.51744 9.47731C6.51568 9.49715 6.51391 9.51755 6.51274 9.53739C6.51215 9.54702 6.51156 9.55609 6.51156 9.56572C6.49862 9.84457 6.55392 10.1189 6.66571 10.3683L5.81728 8.68498L5.70667 8.46564C5.70667 8.46564 5.70549 8.46338 5.7049 8.46281L5.6649 8.38346C5.6649 8.38346 5.66372 8.3812 5.66372 8.38006C5.46897 7.96746 5.4319 7.48515 5.59841 7.02267C5.72726 6.66392 5.96026 6.36863 6.25385 6.15667C6.25503 6.15553 6.25621 6.15497 6.25738 6.15383C6.28151 6.13796 6.30504 6.12209 6.32858 6.10509C6.32858 6.10509 6.32975 6.10452 6.33034 6.10396C6.54863 5.9481 6.73867 5.75313 6.88812 5.5253C6.97755 5.38871 7.05227 5.24022 7.10934 5.08096C7.51297 3.95934 6.89577 2.73514 5.73138 2.34634C4.567 1.95754 3.29612 2.55207 2.89249 3.67369C2.54006 4.65305 2.96604 5.71063 3.85448 6.22184C3.88331 6.23828 3.91273 6.25415 3.94215 6.26945C4.46815 6.58457 4.64525 7.24711 4.33518 7.76966C4.03099 8.28258 3.3679 8.47471 2.82424 8.22024C2.78012 8.197 2.73599 8.1732 2.69304 8.14826C0.864382 7.11449 -0.014055 4.94947 0.706699 2.94541C1.52806 0.661365 4.1163 -0.549234 6.48803 0.242529C8.85916 1.03373 10.1159 3.5269 9.29455 5.81151H9.29396Z"
        fill="url(#paint1_linear_403_5974)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_403_5974"
        x1="9.06569"
        y1="11.9387"
        x2="6.40206"
        y2="7.31273"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stopColor="#D5DD28" />
        <stop offset="0.23" stopColor="#B8C828" />
        <stop offset="0.46" stopColor="#6E942A" />
        <stop offset="0.72" stopColor="#17562C" />
        <stop offset="0.75" stopColor="#174925" />
        <stop offset="0.82" stopColor="#173A1D" />
        <stop offset="0.89" stopColor="#173118" />
        <stop offset="1" stopColor="#172F17" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_403_5974"
        x1="9.90064"
        y1="4.78613"
        x2="-1.46392"
        y2="5.95638"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="#6EC4EB" />
        <stop offset="0.2" stopColor="#65BEE8" />
        <stop offset="0.36" stopColor="#4CB1E1" />
        <stop offset="0.55" stopColor="#299DD7" />
        <stop offset="0.62" stopColor="#2691CB" />
        <stop offset="0.74" stopColor="#2072AC" />
        <stop offset="0.86" stopColor="#194E88" />
        <stop offset="1" stopColor="#0D152E" />
      </linearGradient>
      <clipPath id="clip0_403_5974">
        <rect
          width="9.09091"
          height="12"
          fill="white"
          transform="translate(0.455078)"
        />
      </clipPath>
    </defs>
  </svg>
);
