import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationMetrics = (queryString) => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationMetrics", locationId, queryString],
    queryFn: () =>
      http.get(`${apiUrl}/locations/${locationId}/metrics${queryString}`),
    enabled: !!locationId,
    initialData: {
      data: {
        data: {
          metrics: {},
        },
      },
    },
    select: (data) => {
      return data?.data?.data;
    },
  });
};
