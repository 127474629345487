import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    "& .MuiSvgIcon-root": {
      color: "inherit",
    },
    height: 24,
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
    },
  },
  icon: {
    marginLeft: 0,
    width: 16,
    height: 16,
    marginRight: 8,
  },
  label: {
    fontWeight: 400,
    padding: 0,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
}));

export const CardChip = ({ label, icon, backgroundColor, textColor }) => {
  const classes = useStyles();

  return (
    <Chip
      icon={icon}
      label={label}
      classes={{
        root: classes.root,
        icon: classes.icon,
        label: classes.label,
      }}
      style={{
        backgroundColor,
        color: textColor,
      }}
    />
  );
};
