import React from "react";
import {
  CarpetCareIcon,
  FloorCareIcon,
  GeneralMaintenanceIcon,
  HvacIcon,
  JanitorialIcon,
  PaintingIcon,
  PestControlIcon,
  PlumingAndElectricalIcon,
  PressureWashingIcon,
  WindowWashingIcon,
} from "components/ui/Trades/tradeIcons";
import { NotInterested } from "@material-ui/icons";

const placeholderIcon = <NotInterested style={{ color: "red" }} />;

export const TradeIconSet = {
  "Access Control Services": placeholderIcon,
  "Air Conditioning Services": placeholderIcon,
  "Aluminium Manufacturing": placeholderIcon,
  "Appliance Sales & Service": placeholderIcon,
  "Asbestos Removal": placeholderIcon,
  "Bat Control": placeholderIcon,
  "Bio-Hazard Cleaning": placeholderIcon,
  "Bird Control": placeholderIcon,
  "Boat Decking": placeholderIcon,
  Carpentry: placeholderIcon,
  "Carpet Care": <CarpetCareIcon />,
  "Christmas Lights (Rental & Installations)": placeholderIcon,
  "Commercial Painting": <PaintingIcon />,
  "Construction Cleaning": placeholderIcon,
  "Cooler/Freezer Maintenance": placeholderIcon,
  "COVID Cleaning": placeholderIcon,
  "Crime Clean Up": placeholderIcon,
  "Demolition Services": placeholderIcon,
  Deodorizing: placeholderIcon,
  "Disaster Restoration": placeholderIcon,
  "Disinfection Services": placeholderIcon,
  Electrical: <PlumingAndElectricalIcon />,
  "Fire Damage Restoration": placeholderIcon,
  "Fire Light and Safety": placeholderIcon,
  "Floor Care": <FloorCareIcon />,
  "Floor Resurfacing and Installation": placeholderIcon,
  "General Construction": placeholderIcon,
  "General Maintenance": <GeneralMaintenanceIcon />,
  "Graffiti Removal": placeholderIcon,
  "Gutter Cleaning": placeholderIcon,
  Handyman: placeholderIcon,
  "HVAC and Mechanical": <HvacIcon />,
  "Irrigation System Services": placeholderIcon,
  Janitorial: <JanitorialIcon />,
  Landscaping: placeholderIcon,
  "Lighting Retrofit": placeholderIcon,
  "Locksmith Services": placeholderIcon,
  "Mold Remediation": placeholderIcon,
  "Moving Services": placeholderIcon,
  Painting: <PaintingIcon />,
  "Parking Lot Striping": placeholderIcon,
  "Parking Lot Sweeping": placeholderIcon,
  "Pest Control": <PestControlIcon />,
  "Pigeon Control": placeholderIcon,
  Piping: placeholderIcon,
  Plumbing: <PlumingAndElectricalIcon />,
  "Portable Storage Rental": placeholderIcon,
  "Pressure Washing": <PressureWashingIcon />,
  Restoration: placeholderIcon,
  "Roof Cleaning": placeholderIcon,
  Roofing: placeholderIcon,
  "Screen Repair": placeholderIcon,
  "Shutter Installation": placeholderIcon,
  "Snow Removal": placeholderIcon,
  "Soft Washing": placeholderIcon,
  "Solar Panel Services": placeholderIcon,
  "Staffing Services": placeholderIcon,
  "Terminal Cleaning": placeholderIcon,
  "Trash Removal": placeholderIcon,
  "Upholstery Cleaning": placeholderIcon,
  "Vent Cleaning": placeholderIcon,
  "Waste & Water Treatment": placeholderIcon,
  "Welding Services": placeholderIcon,
  "Window Cleaning": <WindowWashingIcon />,
  "Window Films and Tinting": placeholderIcon,
  "Woodwork Repairs & Restorations": placeholderIcon,
};
