import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { PushPinIcon } from "assets/icons/pushPinIcon";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    aspectRatio: "173 / 86",
    gridTemplateColumns: "663fr 186fr",
    gridTemplateRows: "repeat(3, 132fr)",
    gap: 16,
    maxWidth: "100%",
  },
  mainImageContainer: {
    gridRow: "1 / span 3",
    position: "relative",
  },
  mainImageContainerMultiple: {
    aspectRatio: "663 / 430",
  },
  mainImageContainerSingle: {
    aspectRatio: "173 / 86",
  },
  otherImagesColumn: {
    display: "grid",
    gridRow: "1 / span 3",
    gap: 16,
  },
  imageContainer: {
    aspectRatio: "186 / 132",
    minHeight: 0,
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  pushPinIconContainer: {
    position: "absolute",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#E8F8CB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      width: 24,
      height: 24,
    },
  },
  pushPinIconContainerMain: {
    top: 12,
    right: 12,
    [theme.breakpoints.up("lg")]: {
      top: 16,
      right: 16,
    },
  },
  pushPinIconContainerOther: {
    top: 8,
    right: 8,
    [theme.breakpoints.up("lg")]: {
      top: 12,
      right: 12,
    },
  },
  pushPinIcon: {
    width: "67%",
    height: "67%",
  },
}));

export const DesktopImageGallery = () => {
  const classes = useStyles();
  const { images } = useLocationDetailsContext();

  const pinnedImages = images?.filter((img) => Number(img.is_pinned)) ?? [];

  if (!pinnedImages?.length) return null;

  const multipleImageLayout = pinnedImages.length > 1;

  return (
    <Box className={classes.root}>
      <Box
        className={classNames(
          classes.mainImageContainer,
          multipleImageLayout
            ? classes.mainImageContainerMultiple
            : classes.mainImageContainerSingle
        )}
      >
        <img
          className={classes.image}
          src={pinnedImages[0].file_url}
          alt="Main location"
        />
        <Box
          className={`${classes.pushPinIconContainer} ${classes.pushPinIconContainerMain}`}
        >
          <PushPinIcon className={classes.pushPinIcon} color="#328914" />
        </Box>
      </Box>
      {pinnedImages?.length > 1 && (
        <Box className={classes.otherImagesColumn}>
          {[...Array(3)].map((_, index) => (
            <Box key={index + 1} className={classes.imageContainer}>
              {pinnedImages[index + 1] && (
                <>
                  <img
                    className={classes.image}
                    src={pinnedImages[index + 1].file_url}
                    alt={`Location ${pinnedImages[index + 1].id}`}
                  />
                  <Box
                    className={`${classes.pushPinIconContainer} ${classes.pushPinIconContainerOther}`}
                  >
                    <PushPinIcon
                      className={classes.pushPinIcon}
                      color="#328914"
                    />
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
