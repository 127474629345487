import React from "react";
import { useState } from "react";
import {
  Box,
  makeStyles,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { FilterListIcon } from "assets/icons/filterListIcon";
import {
  useLocationDetailsDispatch,
  useLocationDetailsContext,
} from "pages/locationDetails/context";
import { formatStringAllCapsWithUnderscores } from "pages/locationDetails/utils";

const useStyles = makeStyles((theme) => ({
  mobileFilterButton: {
    width: 40,
    height: 40,
    padding: 0,
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    borderColor: "#D9D9D9",
  },
  desktopFilterButton: {
    background: "#EDF5F8",
    borderRadius: 4,
    minHeight: 0,
    minWidth: 0,
    padding: "0 10px",
    height: "100%",
    textTransform: "none",
    "&:hover": {
      background: "#EDF5F8",
      filter: "brightness(0.985)",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      gap: 6,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 16px",
      "& span": {
        gap: 8,
      },
    },
  },
  buttonBadge: {
    borderRadius: 8,
    background: theme.palette.secondary.main,
    padding: "0 6px",
    height: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.inverted,
    [theme.breakpoints.up("lg")]: {
      padding: "0 8px",
      height: 16,
    },
  },
  badgeText: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 14,
    color: theme.palette.secondary.inverted,
    [theme.breakpoints.up("lg")]: {
      lineHeight: 16,
      fontSize: 12,
    },
  },
  buttonText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      fontSize: 14,
    },
  },
}));

export const GeneralFilters = ({ section }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { selectedFiltersCount } = useLocationDetailsContext();
  const allCapsSection = formatStringAllCapsWithUnderscores(section);

  const badgeCount = selectedFiltersCount[allCapsSection] || 0;

  const dispatch = useLocationDetailsDispatch();
  const openDrawer = () => {
    dispatch({
      type: "OPEN_FILTER_DRAWER",
      activeDrawerSection: allCapsSection,
    });
  };

  return (
    <>
      {isMobile ? (
        <Button
          className={classes.mobileFilterButton}
          disableRipple
          disableElevation
          onClick={openDrawer}
          variant="outlined"
        >
          <FilterListIcon />
        </Button>
      ) : (
        <Button
          className={classes.desktopFilterButton}
          disableRipple
          disableElevation
          onClick={openDrawer}
          variant="contained"
        >
          <Typography className={classes.buttonText}>Filters</Typography>
          <Box className={classes.buttonBadge}>
            <Typography className={classes.badgeText}>{badgeCount}</Typography>
          </Box>
        </Button>
      )}
    </>
  );
};
