import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationImages = () => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationFiles", locationId],
    queryFn: () => http.get(`${apiUrl}/locations/${locationId}/files`),
    enabled: !!locationId,
    select: (data) => data?.data?.data?.image,
  });
};
