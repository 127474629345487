export const WindowWashingIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_411_4378)">
      <path
        d="M1.16675 19.3669C1.16675 19.9192 1.61446 20.3669 2.16675 20.3669H15.5001C16.0524 20.3669 16.5001 19.9192 16.5001 19.3669V8.7002C16.5001 8.5161 16.3508 8.36686 16.1667 8.36686C15.9827 8.36686 15.8334 8.5161 15.8334 8.7002V19.3669C15.8334 19.551 15.6842 19.7002 15.5001 19.7002H2.16675C1.98265 19.7002 1.83341 19.551 1.83341 19.3669V2.7002C1.83341 2.5161 1.98265 2.36686 2.16675 2.36686H13.8167C14.0008 2.36686 14.1501 2.21762 14.1501 2.03353C14.1501 1.84943 14.0008 1.7002 13.8167 1.7002H2.16675C1.61446 1.7002 1.16675 2.14791 1.16675 2.7002V19.3669Z"
        fill="#17457A"
      />
      <path
        d="M2.83333 19.0332H14.8333C15.0174 19.0332 15.1667 18.884 15.1667 18.6999V8.69987C15.1667 8.51578 15.0174 8.36654 14.8333 8.36654C14.6492 8.36654 14.5 8.51578 14.5 8.69987V10.6999H9.16667V3.69987H11.5C11.6841 3.69987 11.8333 3.55063 11.8333 3.36654C11.8333 3.18244 11.6841 3.0332 11.5 3.0332H2.83333C2.64924 3.0332 2.5 3.18244 2.5 3.36654V18.6999C2.5 18.884 2.64924 19.0332 2.83333 19.0332ZM3.16667 11.3665H8.5V18.3665H3.16667V11.3665ZM14.5 11.3665V18.3665H9.16667V11.3665H14.5ZM3.16667 3.69987H8.5V10.6999H3.16667V3.69987Z"
        fill="#17457A"
      />
      <path
        d="M4.16677 6.03366C4.25517 6.03365 4.33994 5.99851 4.40243 5.936L5.40243 4.936C5.52876 4.8052 5.52695 4.5973 5.39837 4.46872C5.2698 4.34015 5.06189 4.33834 4.9311 4.46466L3.9311 5.46466C3.8358 5.56 3.80729 5.70334 3.85888 5.82788C3.91046 5.95242 4.03197 6.03364 4.16677 6.03366Z"
        fill="#78C1CE"
      />
      <path
        d="M4.16677 7.70033C4.25517 7.70031 4.33994 7.66518 4.40243 7.60266L7.0691 4.936C7.19543 4.8052 7.19362 4.5973 7.06504 4.46872C6.93646 4.34015 6.72856 4.33834 6.59777 4.46466L3.9311 7.13133C3.8358 7.22666 3.80729 7.37001 3.85888 7.49455C3.91046 7.61909 4.03197 7.7003 4.16677 7.70033Z"
        fill="#78C1CE"
      />
      <path
        d="M10.1668 13.7007C10.2552 13.7006 10.3399 13.6655 10.4024 13.603L11.4024 12.603C11.5288 12.4722 11.527 12.2643 11.3984 12.1357C11.2698 12.0071 11.0619 12.0053 10.9311 12.1317L9.9311 13.1317C9.8358 13.227 9.80729 13.3703 9.85888 13.4949C9.91046 13.6194 10.032 13.7006 10.1668 13.7007Z"
        fill="#78C1CE"
      />
      <path
        d="M9.93109 15.2697C10.0613 15.3998 10.2723 15.3998 10.4024 15.2697L13.0691 12.603C13.1954 12.4722 13.1936 12.2643 13.065 12.1357C12.9365 12.0071 12.7286 12.0053 12.5978 12.1317L9.93109 14.7983C9.80096 14.9285 9.80096 15.1395 9.93109 15.2697Z"
        fill="#78C1CE"
      />
      <path
        d="M12.1667 4.0335C12.1667 4.2176 12.316 4.36684 12.5001 4.36684C15.1234 4.36684 15.1691 7.2335 15.1697 7.36684C15.1671 7.55093 15.3142 7.70233 15.4982 7.705C15.6823 7.70767 15.8337 7.5606 15.8364 7.3765V7.3695V7.36684C15.8364 7.24417 15.8667 4.36684 18.5064 4.36684C18.6905 4.36684 18.8397 4.2176 18.8397 4.0335C18.8397 3.84941 18.6905 3.70017 18.5064 3.70017C15.8771 3.70017 15.8367 0.822169 15.8364 0.700169C15.8389 0.516074 15.6917 0.364821 15.5076 0.362336C15.3235 0.35985 15.1722 0.507074 15.1697 0.691169V0.697502V0.700169C15.1697 0.822836 15.1394 3.70017 12.5001 3.70017C12.316 3.70017 12.1667 3.84941 12.1667 4.0335ZM15.5031 2.35484C15.7766 3.07649 16.3042 3.67343 16.9867 4.0335C16.3042 4.39358 15.7766 4.99052 15.5031 5.71217C15.2296 4.99052 14.702 4.39358 14.0194 4.0335C14.702 3.67343 15.2296 3.07649 15.5031 2.35484Z"
        fill="#78C1CE"
      />
      <path
        d="M12.1667 5.36656V5.36456C12.1661 5.18047 12.0164 5.03168 11.8323 5.03223C11.6482 5.03278 11.4994 5.18247 11.5 5.36656C11.5 5.44823 11.472 7.36656 9.83333 7.36656C9.64924 7.36656 9.5 7.5158 9.5 7.69989C9.5 7.88399 9.64924 8.03323 9.83333 8.03323C11.472 8.03323 11.5 9.95223 11.5 10.0332V10.0352C11.5006 10.2193 11.6502 10.3681 11.8343 10.3676C12.0184 10.367 12.1672 10.2173 12.1667 10.0332C12.1667 9.95156 12.1947 8.03323 13.8333 8.03323C14.0174 8.03323 14.1667 7.88399 14.1667 7.69989C14.1667 7.5158 14.0174 7.36656 13.8333 7.36656C12.1947 7.36656 12.1667 5.44756 12.1667 5.36656ZM12.6147 7.69989C12.2771 7.92503 12.0071 8.2376 11.8333 8.60423C11.6596 8.2376 11.3895 7.92503 11.052 7.69989C11.3896 7.47484 11.6597 7.16225 11.8333 6.79556C12.007 7.16225 12.2771 7.47484 12.6147 7.69989Z"
        fill="#78C1CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_411_4378">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.367188)"
        />
      </clipPath>
    </defs>
  </svg>
);
