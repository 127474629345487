import { useContext } from "react";
import { LocationDetailsContext } from "pages/locationDetails/context";

export const useLocationDetailsContext = () => {
  const context = useContext(LocationDetailsContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDetails must be used within a LocationDetailsProvider"
    );
  }
  return context;
};
