import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationDetailFilters = () => {
  const { locationId } = useParams();

  return useQuery({
    queryKey: ["locationDetailFilters", locationId],
    queryFn: () => http.get(`${apiUrl}/map/locations/filters`),
    initialData: {
      data: {
        data: {},
      },
    },
    select: (data) => {
      return {
        filters: data?.data?.data,
      };
    },
  });
};
