import React from "react";

const CompassIcon = ({ className, fill = "#747474" }) => (
  <svg
    className={className}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49996 0.666504C3.89996 0.666504 0.166626 4.39984 0.166626 8.99984C0.166626 13.5998 3.89996 17.3332 8.49996 17.3332C13.1 17.3332 16.8333 13.5998 16.8333 8.99984C16.8333 4.39984 13.1 0.666504 8.49996 0.666504ZM8.49996 15.6665C4.82496 15.6665 1.83329 12.6748 1.83329 8.99984C1.83329 5.32484 4.82496 2.33317 8.49996 2.33317C12.175 2.33317 15.1666 5.32484 15.1666 8.99984C15.1666 12.6748 12.175 15.6665 8.49996 15.6665ZM3.91663 13.5832L10.175 10.6748L13.0833 4.4165L6.82496 7.32484L3.91663 13.5832ZM8.49996 8.08317C9.00829 8.08317 9.41663 8.4915 9.41663 8.99984C9.41663 9.50817 9.00829 9.9165 8.49996 9.9165C7.99163 9.9165 7.58329 9.50817 7.58329 8.99984C7.58329 8.4915 7.99163 8.08317 8.49996 8.08317Z"
      fill={fill}
    />
  </svg>
);

export default CompassIcon;
