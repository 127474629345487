import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "auto",
  },
  tableHead: {
    "& th": {
      backgroundColor: "#F8F8F8",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      fontFamily: "Lato",
      letterSpacing: "0.1px",
      borderBottom: "none",
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: 8,
    },
  },
  tableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#F8F8F8",
    },
    "& td": {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.25px",
      borderBottom: "none",
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: 8,
    },
  },
}));

export const Table = ({ columns, data, renderCell }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <MuiTable size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: classes.tableBody }}>
          {data.map((row, index) => (
            <TableRow key={row.id || index} style={{ height: 38 }}>
              {columns.map((column) => (
                <TableCell key={`${row.id || index}-${column.key}`}>
                  {renderCell(column, row[column.key], row, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
