import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import {
  GlobeAltIcon,
  MapPinIcon,
  ClipboardDocumentCheckIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/solid";
import CompassIcon from "assets/icons/compassIcon";
import Popover from "@material-ui/core/Popover";
import StateFilter from "./stateFilter";
import RegionFilter from "./regionFilter";
import TradeFilter from "./tradeFilter";
import WorkticketTypeFilter from "./workticketTypeFilter"; // Import WorkticketTypeFilter
import InsightsFilter from "./insightsFilter"; // Add this import

const SpecialFilters = ({ visibleLayers }) => {
  console.log("=== visibleLayers", visibleLayers);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    filterContainer: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
      //   padding: theme.spacing(1, 0),
    },
    filterItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      cursor: "pointer",
      minWidth: 60,
      color: theme.palette.text.secondary,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    activeFilter: {
      color: theme.palette.primary.main,
    },
    popover: {
      margin: theme.spacing(1),
    },
    icon: {
      width: 20,
      height: 20,
      marginBottom: theme.spacing(0.5),
    },
    mapView: {
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "20%",
        height: "60%",
        width: 1,
        backgroundColor: theme.palette.divider,
      },
      color: theme.palette.primary.main,
    },
    pointsOfInterestCounterContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(2),
    },
    pointsOfInterestCounter: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      fontSize: 14,
      fontWeight: 600,
    },
  }));

  const classes = useStyles();

  const pointsOfInterestCount = 1001;
  const MAX_POINTS_OF_INTEREST = 1000;
  const defaultTrades = [
    "janitorial",
    "carpet",
    "window",
    "pest",
    "hvac",
    "pressure",
  ];

  const pointsOfInterestCategory = (visibleLayers, count) => {
    if (visibleLayers.length > 1) {
      return count === 1 ? "point of interest" : "points of interest";
    } else {
      return count === 1
        ? visibleLayers[0].toLowerCase().slice(0, -1)
        : visibleLayers[0].toLowerCase().slice(0, -1) + "s";
    }
  };

  const pointsOfInterestFullText = (visibleLayers, pointsOfInterestCount) => {
    if (visibleLayers.length === 0) {
      return "";
    }

    if (pointsOfInterestCount > MAX_POINTS_OF_INTEREST) {
      return `Over ${MAX_POINTS_OF_INTEREST.toLocaleString()} ${pointsOfInterestCategory(
        visibleLayers,
        pointsOfInterestCount
      )} within Map Area`;
    } else {
      return `${pointsOfInterestCount.toLocaleString()} ${pointsOfInterestCategory(
        visibleLayers,
        pointsOfInterestCount
      )}`;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [selectedWorkticketTypes, setSelectedWorkticketTypes] = useState([]); // State for selected workticket types
  const [selectedInsights, setSelectedInsights] = useState([]);

  const handleFilterClick = (filterName, event) => {
    if (activeFilter === filterName) {
      setAnchorEl(null);
      setActiveFilter(null);
    } else {
      setAnchorEl(event.currentTarget);
      setActiveFilter(filterName);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveFilter(null);
  };

  const handleStateSelect = (newSelectedStates) => {
    setSelectedStates(newSelectedStates);
    // Add any additional logic needed when states are selected
  };

  const handleRegionSelect = (newSelectedRegions) => {
    setSelectedRegions(newSelectedRegions);
    // Add any additional logic needed when regions are selected
  };

  const handleTradeSelect = (newSelectedTrades) => {
    setSelectedTrades(newSelectedTrades);
    // Add any additional logic needed when trades are selected
  };

  const handleWorkticketTypeSelect = (newSelectedWorkticketTypes) => {
    setSelectedWorkticketTypes(newSelectedWorkticketTypes);
    // Add any additional logic needed when workticket types are selected
  };

  const handleInsightSelect = (newSelectedInsights) => {
    setSelectedInsights(newSelectedInsights);
    // Add any additional logic needed when insights are selected
  };

  return (
    <div className={classes.root}>
      <Box className={classes.filterContainer}>
        <Box display="flex">
          <Box
            className={`${classes.filterItem} ${
              activeFilter === "region" ? classes.activeFilter : ""
            }`}
            onClick={(e) => handleFilterClick("region", e)}
          >
            <MapPinIcon className={classes.icon} />
            <Typography variant="body2">Region</Typography>
          </Box>
          <Box
            className={`${classes.filterItem} ${
              activeFilter === "state" ? classes.activeFilter : ""
            }`}
            onClick={(e) => handleFilterClick("state", e)}
          >
            <CompassIcon className={classes.icon} />
            <Typography variant="body2">State</Typography>
          </Box>
          <Box
            className={`${classes.filterItem} ${
              activeFilter === "trade" ? classes.activeFilter : ""
            }`}
            onClick={(e) => handleFilterClick("trade", e)}
          >
            <ClipboardDocumentCheckIcon className={classes.icon} />
            <Typography variant="body2">Trades Provided</Typography>
          </Box>
          <Box
            className={`${classes.filterItem} ${
              activeFilter === "workticketType" ? classes.activeFilter : ""
            }`}
            onClick={(e) => handleFilterClick("workticketType", e)}
          >
            <Square2StackIcon className={classes.icon} />
            <Typography variant="body2">Workticket Type</Typography>
          </Box>
          <Box
            className={`${classes.filterItem} ${
              activeFilter === "insights" ? classes.activeFilter : ""
            }`}
            onClick={(e) => handleFilterClick("insights", e)}
          >
            <Square3Stack3DIcon className={classes.icon} />
            <Typography variant="body2">Insights Category</Typography>
          </Box>
        </Box>
        <Box
          className={classes.pointsOfInterestCounterContainer}
          display="flex"
          alignItems="flex-end"
        >
          <Box className={classes.pointsOfInterestCounter}>
            {pointsOfInterestFullText(visibleLayers, pointsOfInterestCount)}
          </Box>
        </Box>
      </Box>

      <Popover
        className={classes.popover}
        open={
          Boolean(anchorEl) &&
          (activeFilter === "state" ||
            activeFilter === "region" ||
            activeFilter === "trade" ||
            activeFilter === "workticketType" ||
            activeFilter === "insights")
        }
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: 600,
            maxHeight: 600,
          },
        }}
      >
        {activeFilter === "state" ? (
          <StateFilter
            selectedStates={selectedStates}
            onStateSelect={handleStateSelect}
          />
        ) : activeFilter === "region" ? (
          <RegionFilter
            selectedRegions={selectedRegions}
            onRegionSelect={handleRegionSelect}
          />
        ) : activeFilter === "trade" ? (
          <TradeFilter
            defaultTrades={defaultTrades}
            selectedTrades={selectedTrades}
            onTradeSelect={handleTradeSelect}
          />
        ) : activeFilter === "workticketType" ? (
          <WorkticketTypeFilter
            selectedTypes={selectedWorkticketTypes}
            onTypeSelect={handleWorkticketTypeSelect}
          />
        ) : activeFilter === "insights" ? (
          <InsightsFilter
            selectedInsights={selectedInsights}
            onInsightSelect={handleInsightSelect}
          />
        ) : null}
      </Popover>
    </div>
  );
};

export default SpecialFilters;
