import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useUpdateLocationDetails = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => http.put(`${apiUrl}/locations/${locationId}`, data),
    onMutate: async (newDetails) => {
      await queryClient.cancelQueries(["locationDetails", locationId]);

      const previousDetails = queryClient.getQueryData([
        "locationDetails",
        locationId,
      ]);

      queryClient.setQueryData(["locationDetails", locationId], (old) => {
        if (!old?.data?.data?.location) return old;

        const updatedDetails = { ...old };

        updatedDetails.data.data.location.name = newDetails.name;

        return updatedDetails;
      });

      return { previousDetails };
    },
    onError: (_, __, context) => {
      if (context?.previousDetails) {
        queryClient.setQueryData(
          ["locationDetails", locationId],
          context.previousDetails
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries(["locationDetails", locationId]),
  });
};
