import { useState } from "react";
import moment from "moment";
import {
  Box,
  makeStyles,
  Button,
  useTheme,
  Typography,
  Paper,
  Popover,
} from "@material-ui/core";
import { useFilterState, useFilterDispatch } from "contexts/filterContext";
import { useLocationDetailsDispatch } from "pages/locationDetails/context";
import { DateRangePicker } from "react-date-range";
import { CalendarToday } from "@material-ui/icons";
import ApplyButton from "components/common/Filters/applyButton";
import CancelButton from "components/common/Filters/cancelButton";
import ClearButton from "components/common/Filters/clearButton";
import { defaultStaticRanges } from "components/common/Filters/defaultRanges";
import { formatStringAllCapsWithUnderscores } from "pages/locationDetails/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    gap: 4,
    [theme.breakpoints.up("lg")]: {
      gap: 8,
    },
  },
  button: {
    height: "100%",
    background: "#EDF5F8",
    borderRadius: 4,
    minHeight: 0,
    minWidth: 0,
    padding: "0 10px",
    textTransform: "none",
    "&:hover": {
      background: "#EDF5F8",
      filter: "brightness(0.985)",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      gap: 6,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 16px",
      "& span": {
        gap: 8,
      },
    },
  },
  buttonBadge: {
    borderRadius: 8,
    background: theme.palette.secondary.main,
    padding: "0 6px",
    height: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.inverted,
    [theme.breakpoints.up("lg")]: {
      padding: "0 8px",
      height: 16,
    },
  },
  badgeText: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 14,
    color: theme.palette.secondary.inverted,
    [theme.breakpoints.up("lg")]: {
      lineHeight: 16,
      fontSize: 12,
    },
  },
  calendarIcon: {
    width: 14,
    height: 14,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      width: 18,
      height: 18,
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      fontSize: 14,
    },
  },
  dateSelection: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    background: "#EDF5F8",
    borderRadius: 4,
    minHeight: 0,
    minWidth: 0,
    padding: "0 6px",
    textTransform: "none",
    height: 22,
    "&:hover": {
      background: "#EDF5F8",
      filter: "brightness(0.985)",
    },
    [theme.breakpoints.up("lg")]: {
      gap: 8,
      height: 28,
      padding: "0 8px",
    },
  },
  dateRange: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

export const DateFilter = ({ section }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [startDateCalendar, setStartDateCalendar] = useState(null);
  const [endDateCalendar, setEndDateCalendar] = useState(null);
  const [badgeCount, setBadgeCount] = useState(0);
  const [dateRange, setDateRange] = useState("");

  const { startDate, endDate, key } = useFilterState();
  const dispatchFilters = useFilterDispatch();
  const dispatchLocationDetails = useLocationDetailsDispatch();
  const allCapsSection = formatStringAllCapsWithUnderscores(section);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setStartDateCalendar(new Date(startDate));
    setEndDateCalendar(new Date(endDate));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStartDateCalendar(new Date(startDate));
    setEndDateCalendar(new Date(endDate));
    setAnchorEl(null);
  };

  const handleRangeChange = (payload) => {
    setStartDateCalendar(payload.selection.startDate);
    setEndDateCalendar(payload.selection.endDate);
  };

  const handleDateApply = () => {
    const dataDate = {
      startDate: startDateCalendar,
      endDate: endDateCalendar,
    };
    dispatchFilters({
      type: "SET_DATE",
      ...dataDate,
    });
    dispatchLocationDetails({
      type: `SET_${allCapsSection}_DATES`,
      ...dataDate,
    });
    localStorage.setItem(
      `filter_date_${window.location.pathname}_${section}`,
      JSON.stringify(dataDate)
    );
    const numberOfDays =
      moment(endDateCalendar).diff(moment(startDateCalendar), "days") + 1;
    setBadgeCount(numberOfDays);
    setDateRange(`${numberOfDays} Days`);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClear = () => {
    dispatchFilters({
      type: "CLEAR_DATE_RANGE",
    });
    dispatchLocationDetails({
      type: `SET_${allCapsSection}_DATES`,
    });
    setBadgeCount(0);
    setDateRange(null);
    localStorage.removeItem(
      `filter_date_${window.location.pathname}_${section}`
    );
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Button
        className={classes.button}
        disableRipple
        disableElevation
        variant="contained"
        aria-describedby={id}
        onClick={handleClick}
      >
        <CalendarToday className={classes.calendarIcon} />
        <Typography className={classes.text}>Date</Typography>
        <Box className={classes.buttonBadge}>
          <Typography className={classes.badgeText}>{badgeCount}</Typography>
        </Box>
      </Button>
      <Popover
        id={2}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.filterTooltip}
      >
        <Paper className={classes.paper}>
          <DateRangePicker
            onChange={handleRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={"PreviewArea"}
            months={1}
            showMonthAndYearPickers={false}
            ranges={[
              { startDate: startDateCalendar, endDate: endDateCalendar, key },
            ]}
            direction="horizontal"
            rangeColors={["#3A95C1"]}
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
          />
          <Box className={classes.filterActions}>
            <Box className={classes.filterActionsRight}>
              <ClearButton onClick={handleClear} />
              <CancelButton onClick={handleCancel} />
              <ApplyButton onClick={handleDateApply} />
            </Box>
          </Box>
        </Paper>
      </Popover>
      {dateRange && (
        <Box className={classes.dateSelection}>
          <Typography className={classes.text}>Date Range:&nbsp;</Typography>
          <Typography className={`${classes.text} ${classes.dateRange}`}>
            {dateRange}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
