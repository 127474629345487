import { useHistory } from "react-router-dom";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ChevronRight } from "@material-ui/icons";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleContainer: {
    display: "flex",
    gap: 12,
  },
  mapPinIcon: {
    width: 20,
    height: 20,
    color: "#4D4D4D",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4D4D4D",
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbText: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
    color: theme.palette.text.main,
  },
  locationDashboardBackLink: {
    color: theme.palette.secondary.main,
    textTransform: "none",
    padding: 0,
  },
  chevronRightIcon: {
    color: theme.palette.text.main,
    width: 22,
    height: 22,
  },
}));

export const DesktopHeader = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    details: { data, isLoading },
  } = useLocationDetailsContext();

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <MapPinIcon className={classes.mapPinIcon} />
        {isLoading ? (
          <Skeleton variant="text" width={200} height={24} />
        ) : (
          <Typography className={classes.title}>{data.name}</Typography>
        )}
      </Box>
      <Box className={classes.breadcrumb}>
        <Button
          disableRipple
          className={`${classes.breadcrumbText} ${classes.locationDashboardBackLink}`}
          onClick={() => history.goBack()}
        >
          Location Dashboard
        </Button>
        <ChevronRight className={classes.chevronRightIcon} />
        {isLoading ? (
          <Skeleton variant="text" width={200} height={20} />
        ) : (
          <Typography className={classes.breadcrumbText}>
            {data.name}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
