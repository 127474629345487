import {
  makeStyles,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  CardChip,
} from "pages/locationDetails/components/cards/commonElements";
import { ViewButton } from "pages/locationDetails/components";
import { formatDate } from "pages/locationDetails/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "16px 12px",
    borderRadius: 4,
    border: 0,
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#F8F8F8",
      boxShadow: "none",
      padding: 16,
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    maxWidth: "100%",
    width: "100%",
    minWidth: 0,
  },
  rowGroup: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    flex: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: "#4d4d4d",
  },
  cardLabel: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "flex",
    alignItems: "center",
  },
  dateRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
    maxWidth: "100%",
    width: "100%",
    minWidth: 0,
  },
  dateGroup: {
    display: "flex",
    minWidth: "fit-content",
    flex: "1 1 auto",
  },
  // dateGroupEnd: {
  //   justifyContent: "flex-end",
  // },
  dateText: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: "14px",
    letterSpacing: "0.2px",
    whiteSpace: "nowrap",
    [`@media (min-width: 400px)`]: {
      fontSize: 12,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
  },
  date: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.2px",
    whiteSpace: "nowrap",
    [`@media (min-width: 400px)`]: {
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
  },
  score: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  viewButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const RecentSurveyCard = ({
  end_date,
  id,
  name,
  start_date,
  total_score,
  type_name,
  user,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { photo } = user;

  const surveyTypeMap = {
    "Customer Satisfaction": {
      color: "#78C1CE",
    },
    Quality: {
      color: "#9CBB65",
    },
  };

  const scoreColorSwitch = (score) => {
    switch (true) {
      case score >= 90:
        return "#328914";
      case score >= 75:
        return "#9CBB65";
      case score >= 60:
        return "#EFC945";
      default:
        return "#F07A8C";
    }
  };

  return (
    <Box
      component={isMobile ? "button" : "div"}
      className={classes.root}
      {...(isMobile
        ? {
            onClick: () => history.push(`/surveys/completed/${id}`),
            type: "button",
          }
        : {})}
    >
      <Box className={classes.row}>
        <Box className={classes.rowGroup}>
          <Avatar avatar={photo} />
          <Typography className={classes.title}>{name}</Typography>
        </Box>
        <Box className={classes.viewButtonContainer}>
          <ViewButton
            onClick={() => history.push(`/surveys/completed/${id}`)}
          />
        </Box>
      </Box>
      <Box className={classes.dateRow}>
        <Box className={classes.dateGroup}>
          <Typography component="span" className={classes.dateText}>
            Start Date:&nbsp;
          </Typography>
          <Typography className={classes.date}>
            {formatDate(start_date)}
          </Typography>
        </Box>
        <Box className={[classes.dateGroup, classes.dateGroupEnd]}>
          <Typography component="span" className={classes.dateText}>
            End Date:&nbsp;
          </Typography>
          <Typography className={classes.date}>
            {formatDate(end_date)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.row}>
        <Typography component="span" className={classes.cardLabel}>
          Score:&nbsp;
          <Typography
            component="span"
            className={classes.score}
            style={{ color: scoreColorSwitch(Number(total_score)) }}
          >
            {`${parseFloat(total_score)}%`}
          </Typography>
        </Typography>
        <CardChip
          label={type_name}
          backgroundColor={surveyTypeMap[type_name].color}
          textColor={theme.palette.text.inverted}
        />
      </Box>
    </Box>
  );
};
