import React from "react";

export const ClipboardDocumentAlertIcon = ({
  className,
  color = "#747474",
}) => (
  <svg
    className={className}
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_403_2912)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.98563 4.02902H6.52189L6.52347 4.02902H8.81193C10.0724 4.03081 11.0937 5.04198 11.0937 6.28906V12.567C12.2156 12.567 13.125 11.6675 13.125 10.558V4.10148C13.125 3.0934 12.3634 2.2187 11.3212 2.13315C11.1697 2.12071 11.0179 2.10929 10.8658 2.09888C10.5273 1.45523 9.84684 1.01562 9.06255 1.01562H8.04692C7.26263 1.01562 6.58216 1.45522 6.24367 2.09887C6.09154 2.10928 5.93968 2.12071 5.78811 2.13315C4.77093 2.21665 4.0211 3.0518 3.98563 4.02902ZM8.04692 2.02009C7.486 2.02009 7.03129 2.4698 7.03129 3.02455H10.0782C10.0782 2.4698 9.62347 2.02009 9.06255 2.02009H8.04692Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9375 6.28906C0.9375 5.59562 1.50589 5.03348 2.20704 5.03348H8.80864C9.50979 5.03348 10.0782 5.59562 10.0782 6.28906V13.8225C10.0782 14.516 9.50979 15.0781 8.80864 15.0781H2.20704C1.50589 15.0781 0.9375 14.516 0.9375 13.8225V6.28906ZM7.42782 9.36508C7.60302 9.14849 7.56752 8.83244 7.34852 8.65916C7.12952 8.48589 6.80995 8.521 6.63475 8.7376L4.95792 10.8106L4.34347 10.2029C4.14516 10.0068 3.82363 10.0068 3.62531 10.2029C3.427 10.399 3.427 10.717 3.62531 10.9132L4.64094 11.9176C4.74308 12.0186 4.88388 12.0719 5.02811 12.064C5.17233 12.0561 5.30633 11.9878 5.39656 11.8762L7.42782 9.36508Z"
        fill={color}
      />
      <rect x="2.8125" y="6.64062" width="5.625" height="6.5625" fill={color} />
      <path
        d="M5.625 8.51562V10.0764M5.625 11.325V11.3281V11.3281"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
