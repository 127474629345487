import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";

export const useFetchMapFilters = () => {
  return useQuery({
    queryKey: ["mapFilters"],
    queryFn: () => http.get(`${apiUrl}/map/locations/filters?view=map`),
    initialData: {
      data: {
        data: {},
      },
    },
    select: (data) => {
      return {
        filters: data?.data?.data,
      };
    },
  });
};
