import React from "react";

export const WarningBadgeIcon = ({ className, color = "#747474" }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73526 2.61063C6.2847 1.97875 7.09555 1.57812 8 1.57812C8.90441 1.57812 9.71522 1.97871 10.2647 2.61053C11.1001 2.55217 11.9568 2.84223 12.5964 3.48184C13.236 4.12145 13.5261 4.97818 13.4677 5.81359C14.0995 6.36303 14.5 7.17378 14.5 8.07812C14.5 8.98264 14.0993 9.79353 13.4674 10.343C13.5256 11.1782 13.2355 12.0348 12.596 12.6743C11.9565 13.3138 11.1 13.6039 10.2647 13.5456C9.71528 14.1775 8.90445 14.5781 8 14.5781C7.0956 14.5781 6.2848 14.1776 5.73536 13.5457C4.89994 13.6041 4.0432 13.314 3.40358 12.6744C2.76397 12.0348 2.47391 11.1781 2.53227 10.3427C1.90052 9.79322 1.5 8.98247 1.5 8.07812C1.5 7.17374 1.90056 6.36294 2.53237 5.8135C2.47407 4.97816 2.76414 4.12151 3.40369 3.48195C4.04325 2.84239 4.89991 2.55232 5.73526 2.61063ZM10.4069 6.86874C10.5674 6.64404 10.5153 6.33176 10.2906 6.17126C10.0659 6.01075 9.75364 6.0628 9.59313 6.28751L7.4362 9.30722L6.35355 8.22457C6.15829 8.02931 5.84171 8.02931 5.64645 8.22457C5.45118 8.41983 5.45118 8.73642 5.64645 8.93168L7.14645 10.4317C7.25037 10.5356 7.39476 10.5885 7.54124 10.5764C7.68772 10.5643 7.82144 10.4883 7.90687 10.3687L10.4069 6.86874Z"
      fill={color}
    />
    <rect
      x="4.66699"
      y="4.74463"
      width="6.66667"
      height="6.66667"
      fill={color}
    />
    <path
      d="M8 6.07812V8.57812M8 10.5781V10.5831H8.005"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
