import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationSurveys = (queryString, page = 1) => {
  const { locationId } = useParams();

  const baseQueryString = queryString || `?limit=6&page=${page}`;

  return useQuery({
    queryKey: ["locationSurveys", locationId, baseQueryString, page],
    queryFn: () =>
      http.get(`${apiUrl}/locations/${locationId}/surveys${baseQueryString}`),
    enabled: !!locationId,
    keepPreviousData: true,
    initialData: {
      data: {
        data: {
          surveys: [],
          pagination: {},
        },
      },
    },
    select: (data) => {
      const { surveys, ...pagination } = data?.data?.data;
      return {
        cardData: surveys,
        pagination: {
          currentPage: pagination?.current_page,
          itemsPerPage: pagination?.items_per_page,
          totalItems: pagination?.total_items,
          totalPages: pagination?.total_pages,
        },
      };
    },
  });
};
