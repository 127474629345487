import { Box, makeStyles } from "@material-ui/core";
import { FilterProvider } from "contexts/filterContext";
import { SectionHeader } from "pages/locationDetails/components";
import { BarChart, PieChart } from "./charts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
}));

export const KeyMetrics = () => {
  const classes = useStyles();

  return (
    <FilterProvider>
      <Box className={classes.root}>
        <SectionHeader title="Key Metrics" actions={["date", "filter"]} />
        <Box className={classes.chartsContainer}>
          <PieChart />
          <BarChart />
        </Box>
      </Box>
    </FilterProvider>
  );
};
