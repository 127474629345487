import {
  RecentSurveyCard,
  SectionHeader,
  PaginationBar,
  ZeroState,
  CardGrid,
} from "pages/locationDetails/components";
import { Box, makeStyles } from "@material-ui/core";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import { formatDate } from "pages/locationDetails/utils";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    backgroundColor: theme.palette.background.default,
    width: "100%",
    minWidth: 0,
    [theme.breakpoints.up("md")]: {
      padding: 16,
    },
  },
}));

const CardsSkeleton = ({ count = 6 }) => {
  return (
    <CardGrid>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            height={128}
            style={{
              borderRadius: 16,
              animationDelay: `${index * 30}ms`,
            }}
          />
        ))}
    </CardGrid>
  );
};

export const RecentSurveys = () => {
  const classes = useStyles();
  const {
    surveys: {
      isLoading,
      isFetching,
      data: { cardData, pagination },
    },
  } = useLocationDetailsContext();
  const dispatch = useLocationDetailsDispatch();

  const handlePageChange = (newPage) => {
    dispatch({ type: "SET_SURVEYS_PAGE", page: newPage });
  };

  return (
    <Box className={classes.root}>
      <SectionHeader title="Recent Surveys" />
      {isLoading || isFetching ? (
        <PaginationBar
          data={cardData}
          pagination={pagination}
          onPageChange={handlePageChange}
        >
          {() => <CardsSkeleton />}
        </PaginationBar>
      ) : cardData.length > 0 ? (
        <PaginationBar
          data={cardData}
          pagination={pagination}
          onPageChange={handlePageChange}
        >
          {(currentItems) => (
            <CardGrid>
              {currentItems.map((survey) => (
                <RecentSurveyCard
                  key={`ID: ${survey.id}, ${formatDate(
                    survey.start_date,
                    "MM/DD/YY"
                  )} - ${formatDate(survey.end_date, "MM/DD/YY")}`}
                  {...survey}
                />
              ))}
            </CardGrid>
          )}
        </PaginationBar>
      ) : (
        <ZeroState text="No surveys have been completed yet" />
      )}
    </Box>
  );
};
