export const JanitorialIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 14.4829C12.7793 14.4829 10.5172 12.2208 10.5172 10.0001C10.5172 9.81045 10.3621 9.65527 10.1724 9.65527C9.98276 9.65527 9.82759 9.81045 9.82759 10.0001C9.82759 12.2208 7.56552 14.4829 5.34483 14.4829C5.15517 14.4829 5 14.638 5 14.8277C5 15.0173 5.15517 15.1725 5.34483 15.1725C7.56552 15.1725 9.82759 17.4346 9.82759 19.6553C9.82759 19.8449 9.98276 20.0001 10.1724 20.0001C10.3621 20.0001 10.5172 19.8449 10.5172 19.6553C10.5172 17.4346 12.7793 15.1725 15 15.1725C15.1897 15.1725 15.3448 15.0173 15.3448 14.8277C15.3448 14.638 15.1897 14.4829 15 14.4829ZM13.3448 14.8277C11.9586 15.3967 10.7448 16.6139 10.1724 18.0001C9.60345 16.6139 8.38621 15.4001 7 14.8277C8.38621 14.2587 9.6 13.0415 10.1724 11.6553C10.7414 13.0415 11.9586 14.2553 13.3448 14.8277Z"
      fill="#17457A"
    />
    <path
      d="M8.44834 4.13793C9.95524 4.13793 11.5518 5.73448 11.5518 7.24138C11.5518 7.43104 11.707 7.58621 11.8966 7.58621C12.0863 7.58621 12.2414 7.43104 12.2414 7.24138C12.2414 5.73448 13.838 4.13793 15.3449 4.13793C15.5346 4.13793 15.6897 3.98276 15.6897 3.7931C15.6897 3.60345 15.5346 3.44828 15.3449 3.44828C13.838 3.44828 12.2414 1.85172 12.2414 0.344828C12.2414 0.155172 12.0863 0 11.8966 0C11.707 0 11.5518 0.155172 11.5518 0.344828C11.5518 1.85172 9.95524 3.44828 8.44834 3.44828C8.25869 3.44828 8.10352 3.60345 8.10352 3.7931C8.10352 3.98276 8.25869 4.13793 8.44834 4.13793ZM9.89317 3.7931C10.7483 3.38276 11.4863 2.64828 11.8966 1.78966C12.307 2.64483 13.0414 3.38276 13.9001 3.7931C13.0449 4.20345 12.307 4.93793 11.8966 5.79655C11.4863 4.94138 10.7518 4.20345 9.89317 3.7931Z"
      fill="#17457A"
    />
    <path
      d="M5.69016 11.3794C5.69016 11.5691 5.84533 11.7243 6.03499 11.7243C6.22464 11.7243 6.37982 11.5691 6.37982 11.3794C6.37982 10.3725 7.44188 9.31048 8.44878 9.31048C8.63844 9.31048 8.79361 9.15531 8.79361 8.96565C8.79361 8.776 8.63844 8.62082 8.44878 8.62082C7.44188 8.62082 6.37982 7.55876 6.37982 6.55186C6.37982 6.3622 6.22464 6.20703 6.03499 6.20703C5.84533 6.20703 5.69016 6.3622 5.69016 6.55186C5.69016 7.55876 4.62809 8.62082 3.62119 8.62082C3.43154 8.62082 3.27637 8.776 3.27637 8.96565C3.27637 9.15531 3.43154 9.31048 3.62119 9.31048C4.62809 9.31048 5.69016 10.3725 5.69016 11.3794ZM4.85223 8.96565C5.33844 8.68979 5.75913 8.2691 6.03499 7.78289C6.31085 8.2691 6.73154 8.68979 7.21775 8.96565C6.73154 9.24151 6.31085 9.6622 6.03499 10.1484C5.75913 9.6622 5.33844 9.24151 4.85223 8.96565Z"
      fill="#17457A"
    />
    <path
      d="M16.2075 10C15.2558 10 14.4834 10.7724 14.4834 11.7241C14.4834 12.6759 15.2558 13.4483 16.2075 13.4483C17.1593 13.4483 17.9317 12.6759 17.9317 11.7241C17.9317 10.7724 17.1593 10 16.2075 10ZM16.2075 12.7586C15.6386 12.7586 15.1731 12.2931 15.1731 11.7241C15.1731 11.1552 15.6386 10.6897 16.2075 10.6897C16.7765 10.6897 17.242 11.1552 17.242 11.7241C17.242 12.2931 16.7765 12.7586 16.2075 12.7586Z"
      fill="#78C1CE"
    />
    <path
      d="M4.82796 4.48283C4.82796 3.72076 4.21072 3.10352 3.44865 3.10352C2.68658 3.10352 2.06934 3.72076 2.06934 4.48283C2.06934 5.2449 2.68658 5.86214 3.44865 5.86214C4.21072 5.86214 4.82796 5.2449 4.82796 4.48283ZM2.75899 4.48283C2.75899 4.10352 3.06934 3.79317 3.44865 3.79317C3.82796 3.79317 4.1383 4.10352 4.1383 4.48283C4.1383 4.86214 3.82796 5.17248 3.44865 5.17248C3.06934 5.17248 2.75899 4.86214 2.75899 4.48283Z"
      fill="#78C1CE"
    />
    <path
      d="M5.86261 2.06897C6.43157 2.06897 6.89709 1.60345 6.89709 1.03448C6.89709 0.465517 6.43157 0 5.86261 0C5.29364 0 4.82812 0.465517 4.82812 1.03448C4.82812 1.60345 5.29364 2.06897 5.86261 2.06897ZM5.86261 0.689655C6.05226 0.689655 6.20744 0.844828 6.20744 1.03448C6.20744 1.22414 6.05226 1.37931 5.86261 1.37931C5.67295 1.37931 5.51778 1.22414 5.51778 1.03448C5.51778 0.844828 5.67295 0.689655 5.86261 0.689655Z"
      fill="#78C1CE"
    />
    <path
      d="M14.8275 6.89648C14.2585 6.89648 13.793 7.362 13.793 7.93097C13.793 8.49993 14.2585 8.96545 14.8275 8.96545C15.3964 8.96545 15.8619 8.49993 15.8619 7.93097C15.8619 7.362 15.3964 6.89648 14.8275 6.89648ZM14.8275 8.27579C14.6378 8.27579 14.4826 8.12062 14.4826 7.93097C14.4826 7.74131 14.6378 7.58614 14.8275 7.58614C15.0171 7.58614 15.1723 7.74131 15.1723 7.93097C15.1723 8.12062 15.0171 8.27579 14.8275 8.27579Z"
      fill="#78C1CE"
    />
  </svg>
);
