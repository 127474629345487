import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from "@material-ui/icons/Tune";
import { Typography, Button, TextField, Box, Avatar } from "@material-ui/core";
import MapFilterDrawer from "./mapFilterDrawer";
const MapHeader = ({ onFilterChange }) => {
  const useStyles = makeStyles((theme) => ({
    headerContainer: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    contentWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    userContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    avatar: {
      width: 48,
      height: 48,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 600,
      color: "#78C1CE",
    },
    subHeaderText: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.main,
    },
    searchField: {
      flex: 1,
      maxWidth: 700,
      "& .MuiInputBase-root": {
        height: 36,
      },
    },
    filtersButton: {
      whiteSpace: "nowrap",
    },
    filtersIcon: {
      transform: "rotate(-90deg)",
    },
  }));

  const classes = useStyles();

  const handleFilterClick = () => {
    setFilterDrawerOpen(true);
  };

  const handleFilterClose = () => {
    setFilterDrawerOpen(false);
  };

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.userContainer}>
          <Avatar
            alt="User Avatar"
            src="/path-to-avatar.jpg"
            className={classes.avatar}
          />
          <Box>
            <Typography className={classes.headerText}>
              Good Morning, Thomas!
            </Typography>
            <Typography className={classes.subHeaderText}>
              Let's make this day productive.
            </Typography>
          </Box>
        </Box>

        <TextField
          variant="outlined"
          size="small"
          placeholder="Show me all locations with inspection insights that contain health and..."
          className={classes.searchField}
        />

        <Button
          variant="outlined"
          color="primary"
          startIcon={<TuneIcon className={classes.filtersIcon} />}
          className={classes.filtersButton}
          onClick={handleFilterClick}
        >
          Filters
        </Button>
        <MapFilterDrawer open={filterDrawerOpen} onClose={handleFilterClose} />
      </Box>
    </Box>
  );
};

export default MapHeader;
