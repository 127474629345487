import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { ImageTile } from "./elements";
import { useLocationDetailsContext } from "pages/locationDetails/context";
import { useUploadLocationImages } from "pages/locationDetails/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLabel: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  input: {
    display: "none",
  },
  addImagesButton: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: 0,
    textTransform: "none",
    color: theme.palette.secondary.main,
  },
  imagesGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: 8,
    width: "100%",
  },
}));

export const EditLocationImages = () => {
  const classes = useStyles();
  const { images } = useLocationDetailsContext();
  const { mutate: uploadFiles, isLoading } = useUploadLocationImages();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    uploadFiles(files);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography className={classes.headerLabel}>Location Images</Typography>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            component="span"
            className={classes.addImagesButton}
            disabled={isLoading}
          >
            {isLoading ? "Uploading..." : "Add Images"}
          </Button>
        </label>
      </Box>
      {images?.length > 0 && (
        <Box className={classes.imagesGrid}>
          {images.map((image, index) => (
            <ImageTile key={index} image={image} />
          ))}
        </Box>
      )}
    </Box>
  );
};
