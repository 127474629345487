import commentsNone from "./comments-none.png";

export const CommentsNone = ({
  className,
  width = "auto",
  height = "auto",
}) => (
  <img
    className={className}
    src={commentsNone}
    alt="No Comments Illustration"
    width={width}
    height={height}
  />
);
