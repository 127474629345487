import { useState } from "react";
import { Box, Card, CardActionArea, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ExclamationTriangleIcon,
  FlagIcon,
  TagIcon,
  ExclamationCircleIcon,
  BoltIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import HealthIcon from "assets/icons/insights/health.png";
import GeneralIcon from "assets/icons/insights/general.png";
import AssetIcon from "assets/icons/insights/asset.png";
import QualityIcon from "assets/icons/insights/quality.png";
import ServiceRecIcon from "assets/icons/insights/service-rec.png";
import SLACIcon from "assets/icons/insights/sla.png";
import VerificationIcon from "assets/icons/insights/verification.png";

const insights = [
  { id: "health", name: "Health and Safety Risks", icon: HealthIcon },
  { id: "general", name: "General Observations", icon: GeneralIcon },
  { id: "asset", name: "Asset Tagging", icon: AssetIcon },
  { id: "quality", name: "Quality Issues and Concerns", icon: QualityIcon },
  { id: "service-rec", name: "Service Recommendations", icon: ServiceRecIcon },
  { id: "sla", name: "SLA Compliance", icon: SLACIcon },
  { id: "verification", name: "Service Verification", icon: VerificationIcon },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 400,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    paddingLeft: 8,
  },
  insightsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
    marginTop: theme.spacing(1.5),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&.selected": {
      border: `1px solid #4F98BC`,
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: 32,
  },
  iconContainer: {
    width: "auto",
    height: 20,
    borderRadius: 4,
    marginRight: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  insightName: {
    fontSize: 13,
    color: theme.palette.text.primary,
    textAlign: "left",
    lineHeight: 1.2,
  },
}));

const InsightsFilter = ({ selectedInsights = [], onInsightSelect }) => {
  const classes = useStyles();

  const handleInsightToggle = (insightId) => {
    if (selectedInsights.includes(insightId)) {
      onInsightSelect(selectedInsights.filter((id) => id !== insightId));
    } else {
      onInsightSelect([...selectedInsights, insightId]);
    }
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Insights Category
      </Typography>

      <Box className={classes.insightsContainer}>
        {insights.map((insight) => (
          <Card
            key={insight.id}
            className={`${classes.card} ${
              selectedInsights.includes(insight.id) ? "selected" : ""
            }`}
          >
            <CardActionArea
              className={classes.cardAction}
              onClick={() => handleInsightToggle(insight.id)}
            >
              <Box className={classes.iconContainer}>
                <img
                  src={insight.icon}
                  alt={insight.name}
                  style={{ width: 20, height: 20 }}
                />
              </Box>
              <Typography className={classes.insightName}>
                {insight.name}
              </Typography>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default InsightsFilter;
