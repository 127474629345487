import clipboardMagnifyingGlass from "./clipboard-magnifying-glass.png";

export const ClipboardMagnifyingGlass = ({
  className,
  width = "auto",
  height = "auto",
}) => (
  <img
    className={className}
    src={clipboardMagnifyingGlass}
    alt="Clipboard Magnifying Glass Illustration"
    width={width}
    height={height}
  />
);
