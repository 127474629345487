import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ChevronRight, ChevronLeft } from "@material-ui/icons/index";
import { transform } from "lodash";
import walgreensImage from "../../../assets/walgreens-test-1.png";
import walgreensImage2 from "../../../assets/walgreens-test-2.png";

const mockFrames = [
  {
    id: 1,
    image: walgreensImage,
  },
  {
    id: 2,
    image: walgreensImage2,
  },
  {
    id: 3,
    image: "https://placehold.co/250x250?text=3",
  },
  {
    id: 4,
    image: "https://placehold.co/250x250?text=4",
  },
  {
    id: 5,
    image: "https://placehold.co/250x250?text=5",
  },
  {
    id: 6,
    image: "https://placehold.co/250x250?text=6",
  },
  {
    id: 7,
    image: "https://placehold.co/250x250?text=7",
  },
];

const useStyles = makeStyles({
  root: {
    height: 172,
    // width: 250,
    // border: "solid 1px blue",
    position: "relative",
    overflow: "hidden",
  },
  carouselContainer: {
    display: "flex",
    overflow: "hidden",
    scrollSnapType: "x mandatory",
    height: "100%",
  },
  controlButton: {
    backgroundColor: "white",
    // border: "solid 1px black",
    color: "#4F98BC",
    borderRadius: "100%",
    width: 30,
    height: 30,
    minWidth: 0,
    padding: 0,
    top: 70,
    position: "absolute",
    "&:hover": {
      transition: "0.2s ease-in",
      backgroundColor: "white",
      opacity: 0.8,
    },
  },
  controlButtonLeft: {
    left: 12,
  },
  controlButtonRight: {
    right: 12,
  },

  frame: {
    width: "100%",
    height: "100%",
    flexShrink: 0,
    scrollSnapAlign: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  dotsContainer: {
    position: "absolute",
    bottom: 10,
    left: "50%",
    display: "flex",
    justifyContent: "center",
    transform: "translateX(-50%)",
  },
  dot: {
    height: 5,
    width: 5,
    margin: "0 3px",
    borderRadius: "50%",
    backgroundColor: "#bbb",
    display: "inline-block",
    cursor: "pointer",
  },
  activeDot: {
    backgroundColor: "white",
  },
});

const ImageCarousel = () => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const scrollToImage = (index) => {
    if (carouselRef.current) {
      const scrollAmount = index * carouselRef.current.offsetWidth;

      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + mockFrames.length) % mockFrames.length;

    setCurrentIndex(newIndex);
    scrollToImage(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % mockFrames.length;

    setCurrentIndex(newIndex);
    scrollToImage(newIndex);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    scrollToImage(index);
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      const preventScroll = (e) => {
        e.preventDefault();
      };

      carousel.addEventListener("wheel", preventScroll, { passive: false });

      return () => carousel.removeEventListener("wheel", preventScroll);
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Box>
        {currentIndex > 0 && (
          <Button
            aria-label="Previous image"
            className={`${classes.controlButton} ${classes.controlButtonLeft}`}
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            <ChevronLeft color="#4F98BC" />
          </Button>
        )}

        {currentIndex < mockFrames.length - 1 && ( // Only render if not at the last image
          <Button
            aria-label="Next image"
            className={`${classes.controlButton} ${classes.controlButtonRight}`}
            onClick={handleNext}
            disabled={currentIndex === mockFrames.length - 1}
          >
            <ChevronRight />
          </Button>
        )}
      </Box>
      <Box className={classes.carouselContainer} ref={carouselRef}>
        {mockFrames.map((frame, index) => (
          <Box
            aria-hidden={currentIndex !== index}
            key={frame.id}
            className={classes.frame}
          >
            <img
              src={frame.image}
              alt={`Frame ${frame.id}`}
              className={classes.image}
            />
          </Box>
        ))}
      </Box>
      <Box className={classes.dotsContainer}>
        {mockFrames.map((_, index) => (
          <span
            key={index}
            className={`${classes.dot} ${
              currentIndex === index ? classes.activeDot : ""
            }`}
            onClick={() => handleDotClick(index)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === "Enter" && handleDotClick(index)}
            aria-label={`Go to image ${index + 1}`}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
