export const formatDate = (date) => new Date(date).toLocaleDateString();

export const formatStringAllCapsWithUnderscores = (string) =>
  string?.toUpperCase().replace(/\s+/g, "_");
export const formatStringReadable = (string) =>
  string
    ?.toLowerCase()
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
